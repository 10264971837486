import React, { useState, useEffect } from "react";
import StudyConcept from "./StudyConcept";
import { STUDY_CONCEPTS } from "../data";

export default function StudyConcepts() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        prevIndex === STUDY_CONCEPTS.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000); // 2000ms = 2 seconds

    return () => clearInterval(interval); // Clean up on unmount
  }, []);

  return (
    <main>
    <section id="core-concepts">
    <h2 style={{textAlign: 'center', color: "white"}}>Time to get started!</h2>
    <ul>
      <li className="active" style={{color:"white"}}>
        <StudyConcept key={STUDY_CONCEPTS[currentIndex].title} {...STUDY_CONCEPTS[currentIndex]} />
      </li>
    </ul>
  </section>
  </main>
);
}
