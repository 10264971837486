import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from '../ScrolltopPage';
import Ccna from './courses/ccna/ccna'; // Import the page component
import Database from './courses/database/database';
import DatabaseCommands from './courses/database/database_commands';
import IT from './courses/info-tech/infotech';
import Maths from './courses/maths/math';
import Securityplus from './courses/security+/securityplus';
import CcnaCommand from './courses/ccna/ccna_command';
import SecurityplusCommands from './courses/security+/securityplus_commands';
import App from '../App';
import Sitemap from './SiteMap';
import Quizmap from './Quizmap';
import Footer from './Footer/Footer2';
import CCNAChapter1 from './courses/ccna/ccna1/CcnaQuestionCard';
import CCNAChapter2 from './courses/ccna/ccna1/CcnaQuestionCard';
import CCNAChapter3 from './courses/ccna/ccna1/CcnaQuestionCard';
import CCNAChapter4 from './courses/ccna/ccna1/CcnaQuestionCard';
import CCNAChapter5 from './courses/ccna/ccna1/CcnaQuestionCard';
import CCNAChapter6 from './courses/ccna/ccna1/CcnaQuestionCard';
import CCNAChapter6SPT from './courses/ccna/ccna1/chapter-6/ch6_skills_PT';
import CCNAChapter7 from './courses/ccna/ccna1/CcnaQuestionCard';
import CCNAChapter8 from './courses/ccna/ccna1/CcnaQuestionCard';
import CCNAChapter9 from './courses/ccna/ccna1/CcnaQuestionCard';
import CCNAChapter10 from './courses/ccna/ccna1/CcnaQuestionCard';
import CCNAChapter11 from './courses/ccna/ccna1/CcnaQuestionCard';
import CCNAITNPSA from './courses/ccna/ccna1/Practice-PTA/ITN_PSA_PT';
import PracticeExam from './courses/ccna/ccna1/CcnaQuestionCard';
import Exam from './courses/ccna/ccna1/CcnaQuestionCard';
import CCNA1Quiz from './courses/ccna/ccna1/ccna1_quiz/QuizApp';
import CCNA1ChapterQuiz from './courses/ccna/ccna1/ccna1_quiz/ChapterQuiz';
import CCNA1ExamQuiz from './courses/ccna/ccna1/ccna1_quiz/ExamQuiz';

import CCNA2Chapter1 from './courses/ccna/ccna2/CcnaQuestionCard';
import CCNA2Chapter2 from './courses/ccna/ccna2/CcnaQuestionCard';
import SIC_PSA_PT from './courses/ccna/ccna2/Chapter2_SIC_Practice_Skills_Assessment/SIC_PSA_PT';
import CCNA2Chapter3 from './courses/ccna/ccna2/CcnaQuestionCard';
import CCNA2Chapter4 from './courses/ccna/ccna2/CcnaQuestionCard';
import CCNA2Chapter5 from './courses/ccna/ccna2/CcnaQuestionCard';
import CCNA2Chapter6 from './courses/ccna/ccna2/CcnaQuestionCard';
import CCNA2Chapter7 from './courses/ccna/ccna2/CcnaQuestionCard';
import CH7_SIC_PSA_PT from './courses/ccna/ccna2/Chapter7_SIC_Practice_Skills_Assessment/SIC_PSA_PT'
import CCNA2Chapter8 from './courses/ccna/ccna2/CcnaQuestionCard';
import CCNA2Chapter9 from './courses/ccna/ccna2/CcnaQuestionCard';
import CCNA2Chapter10 from './courses/ccna/ccna2/CcnaQuestionCard';
import CCNA2Chapter10Exam from './courses/ccna/ccna2/CcnaQuestionCard';
import CCNA2Exam from './courses/ccna/ccna2/CcnaQuestionCard';
import CCNA2Exam2024 from './courses/ccna/ccna2/CcnaQuestionCard';
import CCNA2Quiz from './courses/ccna/ccna2/ccna2_quiz/QuizApp';
import CCNA2ChapterQuiz from './courses/ccna/ccna2/ccna2_quiz/ChapterQuiz';
import CCNA2ExamQuiz from './courses/ccna/ccna2/ccna2_quiz/ExamQuiz';


import CCNA3Chapter1 from './courses/ccna/ccna3/CcnaQuestionCard';
import CCNA3Chapter2 from './courses/ccna/ccna3/CcnaQuestionCard';
import CCNA3Chapter3 from './courses/ccna/ccna3/CcnaQuestionCard';
import CCNA3Chapter4 from './courses/ccna/ccna3/CcnaQuestionCard';
import CCNA3Chapter5 from './courses/ccna/ccna3/CcnaQuestionCard';
import CCNA3Chapter6 from './courses/ccna/ccna3/CcnaQuestionCard';
import CCNA3Chapter7 from './courses/ccna/ccna3/CcnaQuestionCard';
import CCNA3Chapter8 from './courses/ccna/ccna3/CcnaQuestionCard';
import CCNA3Chapter9 from './courses/ccna/ccna3/CcnaQuestionCard';
import CCNA3Chapter10 from './courses/ccna/ccna3/CcnaQuestionCard';
import CCNA3Quiz from './courses/ccna/ccna3/ccna3_quiz/QuizApp';
import CCNA3ChapterQuiz from './courses/ccna/ccna3/ccna3_quiz/ChapterQuiz';
import CCNA3ExamQuiz from './courses/ccna/ccna3/ccna3_quiz/ExamQuiz';






import Calculators from './courses/ccna/calculator/calculators';
import IPv4_Calculator from './courses/ccna/calculator/ipv4_calculator';
import IPv6_Calculator from './courses/ccna/calculator/ipv6_calculator';
import BinaryConverter from './courses/ccna/calculator/binary_converter';
import OctalConverter from './courses/ccna/calculator/octal_converter';
import HexadecimalConverter from './courses/ccna/calculator/hexadecimal_converter';
import DecimalConverter from './courses/ccna/calculator/decimal_converter';
import PopularCourses from './Course_Card/PopularCourses';
//import { PageViewProvider } from './Course_Card/PageViewContext';
import TrackingComponent from './Course_Card/TrackingComponent';
import TotalVisits from './Course_Card/TotalVisits';
import Settings from './Settings/Setting';
import ThemeProvider from './Settings/Theme';
import Language from './Settings/Language';
import './Settings/i18n';
import BackToTop from './Settings/Back_To_Top';
import Top from './TopPage';
import NewFooter from './Footer/NewFooter';
import PageNotFound from './PageNotFound';
import AddCourse from './addcourse';
import UpdateNotification from './UpdateNotification/UpdateNotification';




function Routepath() {
    return (
      <Router>
         {/* <PageViewProvider> */}
          {/* <TrackingComponent />  */}
          <ThemeProvider>
          {/* <Settings /> */}
          {/* <Language /> */}
          {/* <BackToTop /> */}
          
        <ScrollToTop />
        <Top/>
        <Routes>
        <Route path="/" element={<App />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/courses/PopularCourses" element={<PopularCourses />} />
          <Route path="/ccna/ccna" element={<Ccna />} />
          <Route path='/ccna/ccna_command' element={<CcnaCommand />} />
          <Route path="/database" element={<Database />} />
          <Route path="/database/database_commands" element={<DatabaseCommands />} />
          <Route path="/infotech" element={<IT />} />
          <Route path="/math" element={<Maths />} />
          <Route path="/security+/securityplus_commands" element={<Securityplus />} />
          <Route path="/security+/securityplus_commands" element={<SecurityplusCommands />} />
      {/* Slugified question route */}
      <Route path="/ccna/ccna1/chapter-1/:questionSlug?" element={<CCNAChapter1 />} />
      <Route path="/ccna/ccna1/chapter-2/:questionSlug?" element={<CCNAChapter2 />} />
      <Route path="/ccna/ccna1/chapter-3/:questionSlug?" element={<CCNAChapter3 />} />
      <Route path="/ccna/ccna1/chapter-4/:questionSlug?" element={<CCNAChapter4 />} />
      <Route path="/ccna/ccna1/chapter-5/:questionSlug?" element={<CCNAChapter5 />} /> 
      <Route path="/ccna/ccna1/chapter-6/:questionSlug?" element={<CCNAChapter6 />} /> 
      <Route path="/ccna/ccna1/chapter-6/ch6_skills_PT" element={<CCNAChapter6SPT />} /> 
      <Route path="/ccna/ccna1/chapter-7/:questionSlug?" element={<CCNAChapter7 />} />
      <Route path="/ccna/ccna1/chapter-8/:questionSlug?" element={<CCNAChapter8 />} /> 
      <Route path="/ccna/ccna1/chapter-9/:questionSlug?" element={<CCNAChapter9 />} />
      <Route path="/ccna/ccna1/chapter-10/:questionSlug?" element={<CCNAChapter10 />} />
      <Route path="/ccna/ccna1/chapter-11/:questionSlug?" element={<CCNAChapter11 />} /> 
      <Route path="/ccna/ccna1/Practice-PTA/ITN_PSA_PT" element={<CCNAITNPSA />} />
      <Route path="/ccna/ccna1/practice_exam/:questionSlug?" element={<PracticeExam/>} />
      <Route path="/ccna/ccna1/exam/:questionSlug?" element={<Exam />} />
      <Route path="/ccna/ccna1/ccna1_quiz/QuizApp" element={<CCNA1Quiz />} />
      <Route path="/ccna/ccna1/ccna1_quiz/ChapterQuiz" element={<CCNA1ChapterQuiz />} />
      <Route path="/ccna/ccna1/ccna1_quiz/ExamQuiz" element={<CCNA1ExamQuiz />} /> 

          
          <Route path="/ccna/ccna2/chapter-1/:questionSlug?" element={<CCNA2Chapter1 />} />  
          <Route path="/ccna/ccna2/chapter-2/:questionSlug?" element={<CCNA2Chapter2 />} />  
          <Route path="ccna/ccna2/Chapter2_SIC_PracticeSkills_Assessment/SIC_PSA_PT" element={<SIC_PSA_PT />} />  
          <Route path="/ccna/ccna2/chapter-3/:questionSlug?" element={<CCNA2Chapter3 />} />  
          <Route path="/ccna/ccna2/chapter-4/:questionSlug?" element={<CCNA2Chapter4 />} />  
          <Route path="/ccna/ccna2/chapter-5/:questionSlug?" element={<CCNA2Chapter5 />} />  
          <Route path="/ccna/ccna2/chapter-6/:questionSlug?" element={<CCNA2Chapter6 />} />  
          <Route path="/ccna/ccna2/chapter-7/:questionSlug?" element={<CCNA2Chapter7 />} />
          <Route path="ccna/ccna2/Chapter7_SIC_PracticeSkills_Assessment/SIC_PSA_PT" element={<CH7_SIC_PSA_PT />} />  
          <Route path="/ccna/ccna2/chapter-8/:questionSlug?" element={<CCNA2Chapter8 />} />
          <Route path="/ccna/ccna2/chapter-9/:questionSlug?" element={<CCNA2Chapter9 />} />
          <Route path="/ccna/ccna2/chapter-10/:questionSlug?" element={<CCNA2Chapter10 />} />
          <Route path="/ccna/ccna2/chapter-10-Exam/:questionSlug?" element={<CCNA2Chapter10Exam />} />
          <Route path="/ccna/ccna2/Exam/:questionSlug?" element={<CCNA2Exam />} />
          <Route path="/ccna/ccna2/Exam-Version-7/:questionSlug?" element={<CCNA2Exam2024 />} />
          <Route path="/ccna/ccna2/ccna2_quiz/QuizApp" element={<CCNA2Quiz />} />
          <Route path="/ccna/ccna2/ccna2_quiz/ChapterQuiz" element={<CCNA2ChapterQuiz />} />
          <Route path="/ccna/ccna2/ccna2_quiz/ExamQuiz" element={<CCNA2ExamQuiz />} /> 


          <Route path="/ccna/ccna3/chapter-1/:questionSlug?" element={<CCNA3Chapter1 />} />  
          <Route path="/ccna/ccna3/chapter-2/:questionSlug?" element={<CCNA3Chapter2 />} />  
          <Route path="/ccna/ccna3/chapter-3/:questionSlug?" element={<CCNA3Chapter3 />} />  
          <Route path="/ccna/ccna3/chapter-4/:questionSlug?" element={<CCNA3Chapter4 />} />  
          <Route path="/ccna/ccna3/chapter-5/:questionSlug?" element={<CCNA3Chapter5 />} /> 
          <Route path="/ccna/ccna3/chapter-6/:questionSlug?" element={<CCNA3Chapter6 />} />  
          <Route path="/ccna/ccna3/chapter-7/:questionSlug?" element={<CCNA3Chapter7 />} />  
          <Route path="/ccna/ccna3/chapter-8/:questionSlug?" element={<CCNA3Chapter8 />} />  
          <Route path="/ccna/ccna3/chapter-9/:questionSlug?" element={<CCNA3Chapter9 />} />  
          <Route path="/ccna/ccna3/chapter-10/:questionSlug?" element={<CCNA3Chapter10 />} /> 
          <Route path="/ccna/ccna3/ccna3_quiz/QuizApp" element={<CCNA3Quiz />} /> 
          <Route path="/ccna/ccna3/ccna3_quiz/ChapterQuiz" element={<CCNA3ChapterQuiz />} />
          <Route path="/ccna/ccna3/ccna3_quiz/ExamQuiz" element={<CCNA3ExamQuiz />} /> 

 


          <Route path="/ccna/calculator/calculators" element={<Calculators />} />
          <Route path="/ccna/calculator/ipv4_calculator" element={<IPv4_Calculator />} />
          <Route path="/ccna/calculator/ipv6_calculator" element={<IPv6_Calculator />} />
          <Route path="/ccna/calculator/binary_converter" element={<BinaryConverter />} />
          <Route path="/ccna/calculator/octal_converter" element={<OctalConverter />} />
          <Route path="/ccna/calculator/hexadecimal_converter" element={<HexadecimalConverter />} />
          <Route path="/ccna/calculator/decimal_converter" element={<DecimalConverter />} />
          {/* <Route path="/admin_add_course" element={<AdminAddCourse/>}/> */}
          {/* <Route path="/admin_add_course" components={AdminAddCourse} /> */}
          {/* <Route path="/components/sitemap" component={Sitemap} /> */}
          <Route path='/SiteMap' element={<Sitemap />}/>
          <Route path = '/Quizmap' element ={<Quizmap/>}/>
          {/* <Route path="/src/translate_smart" element={<Translate_Smart/>}/> */}


           <Route path="/addcourse" component={AddCourse} />

          {/* Add other routes here */}
        </Routes>
        {/* <TotalVisits />
        </PageViewProvider> */}
        <NewFooter></NewFooter>
        <UpdateNotification/>
        </ThemeProvider>
        {/* </PageViewProvider> */}
      </Router>
    );
  }
  
  export default Routepath;