import React, { useState, useEffect, useCallback } from 'react';
import CCNA1_Quiz_Bank from '../../../../Settings/locales/en/ccna2_translation.json';
import Question from './Question';
import Result from './Result';
import './Quiz.css';
import ScrollToTop from '../../../../../ScrolltopPage';
import { Box, Typography, LinearProgress, Select, MenuItem, Button } from '@mui/material';
import NavigationBar from '../NavigationBar';

const linksArray = ["Home", "Courses", "Calculator"];

const ExamQuiz = () => {
    const [questions, setQuestions] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [timer, setTimer] = useState(6666); // 1 hour 51 minutes in seconds
    const [showResult, setShowResult] = useState(false);
    const [filterOption, setFilterOption] = useState("all");
    const [showSubmitDialog, setShowSubmitDialog] = useState(false);
    const questionsPerPage = 10;
    const [startTime, setStartTime] = useState(null);

    // Fisher-Yates Shuffle
    const fisherYatesShuffle = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1)); // Get a random index from 0 to i
            [array[i], array[j]] = [array[j], array[i]]; // Swap the elements
        }
    };

    const initializeExam = () => {
        const allQuestions = Object.values(CCNA1_Quiz_Bank).flat();
        
        // Use Fisher-Yates shuffle instead of sort with Math.random()
        fisherYatesShuffle(allQuestions);
        const shuffledQuestions = allQuestions.slice(0, 150);

        shuffledQuestions.forEach((question) => {
            if (question.type === "multiple-choice" && Array.isArray(question.answers)) {
                fisherYatesShuffle(question.answers); // Shuffle the answers for multiple-choice questions
                question.points = question.answers.filter((a) => a.isCorrect).length;
            } else if (question.type === "matching") {
                question.points = question.items?.length || 0; // Safely access items
                fisherYatesShuffle(question.options); // Shuffle options for matching questions
            }
        });

        setQuestions(shuffledQuestions);
        setStartTime(Date.now());
        setTimer(6666);
    };

    const handleSubmit = useCallback(() => {
        setShowSubmitDialog(false);
        setShowResult(true);
    }, []);

    useEffect(() => {
        if (startTime) {
            const interval = setInterval(() => {
                const elapsed = Math.floor((Date.now() - startTime) / 1000);
                const remainingTime = 6666 - elapsed;

                if (remainingTime <= 0) {
                    clearInterval(interval);
                    handleSubmit();
                } else {
                    setTimer(remainingTime);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [startTime, handleSubmit]);

    useEffect(() => {
        initializeExam();
    }, []);

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;
        return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleAnswerSelect = (questionId, answerIndex) => {
        setSelectedAnswers((prevAnswers) => {
            const question = questions.find((q) => q.id === questionId);
            
            if (question && question.type === "multiple-choice") {
                const currentSelection = prevAnswers[questionId] || [];
                const correctAnswersCount = question.answers?.filter((a) => a.isCorrect).length || 0;

                if (currentSelection.includes(answerIndex)) {
                    return {
                        ...prevAnswers,
                        [questionId]: currentSelection.filter((idx) => idx !== answerIndex),
                    };
                } else {
                    if (currentSelection.length < correctAnswersCount) {
                        return {
                            ...prevAnswers,
                            [questionId]: [...currentSelection, answerIndex],
                        };
                    } else {
                        return {
                            ...prevAnswers,
                            [questionId]: [answerIndex],
                        };
                    }
                }
            } else if (question && question.type === "matching") {
                // For matching, track the selected pairings
                const currentMatches = prevAnswers[questionId] || {};
                return {
                    ...prevAnswers,
                    [questionId]: {
                        ...currentMatches,
                        [answerIndex]: currentMatches[answerIndex] ? null : answerIndex, // Toggle the match state
                    },
                };
            }

            return prevAnswers;
        });
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(questions.length / questionsPerPage) - 1) {
            setCurrentPage(currentPage + 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const handleFilterChange = (event) => {
        setFilterOption(event.target.value);
    };

    const handleOpenSubmitDialog = () => {
        setShowSubmitDialog(true);
    };

    const handleCloseSubmitDialog = () => {
        setShowSubmitDialog(false);
    };

    const questionsAnswered = Object.keys(selectedAnswers).length;

    const displayedQuestions = questions
        .filter((q) => {
            const correctAnswersCount = q.answers?.filter((a) => a.isCorrect).length || 0;
            const isAnswered = selectedAnswers[q.id] && selectedAnswers[q.id].length > 0;
            const isUnanswered = !isAnswered || selectedAnswers[q.id]?.length < correctAnswersCount;

            if (filterOption === "answered") return isAnswered;
            if (filterOption === "unanswered") return isUnanswered;
            return true;
        })
        .slice(currentPage * questionsPerPage, (currentPage + 1) * questionsPerPage);

    const timeRemainingPercent = (timer / 6666) * 100;

    return showResult ? (
        <>
            <NavigationBar links={linksArray} />
            <ScrollToTop />
            <Result questions={questions} selectedAnswers={selectedAnswers} />
        </>
    ) : (
        <div>
            <div className="quiz-container">
                <div className="sticky-top">
                    <h2 className="chapter-name">Exam</h2>
                    <div className="progress-info">
                        <Box sx={{ width: '100%', marginBottom: 2 }}>
                            <LinearProgress variant="determinate" value={(questionsAnswered / questions.length) * 100} />
                        </Box>
                        <div className="answered-info">
                            <Typography variant="body2">
                                {questionsAnswered} / {questions.length} questions
                            </Typography>
                        </div>
                    </div>
                    <div className="timer-progress">
                        <Box sx={{ width: '100%', marginBottom: 2 }}>
                            <LinearProgress
                                variant="determinate"
                                value={timeRemainingPercent}
                                color={timeRemainingPercent > 50 ? "success" : timeRemainingPercent > 25 ? "warning" : "error"}
                            />
                        </Box>
                        <div className="timer">
                            <Typography variant="body2">{formatTime(timer)}</Typography>
                        </div>
                    </div>
                </div>
                <div className="question-list">
                    {displayedQuestions.map((question, index) => (
                        <Question
                            key={question.id}
                            question={question}
                            onSelectAnswer={handleAnswerSelect}
                            selectedAnswers={selectedAnswers[question.id] || []}
                            questionNumber={currentPage * questionsPerPage + index + 1}
                        />
                    ))}
                </div>
                <div className="sticky-bottom quiz-navigation">
                    <Button onClick={handlePrevPage} disabled={currentPage === 0}>Previous</Button>
                    <Select
                        value={filterOption}
                        onChange={handleFilterChange}
                        sx={{
                            color: '#1976d2',
                            '& .MuiSvgIcon-root': { color: '#1976d2' },
                            '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                        }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    background: 'rgba(17, 14, 14, 0.75)',
                                    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
                                    backdropFilter: 'blur(2px)',
                                    WebkitBackdropFilter: 'blur(2px)',
                                    borderRadius: '10px',
                                    border: '1px solid rgba(255, 255, 255, 0.18)',
                                    '& .MuiMenuItem-root': { color: '#1976d2' },
                                },
                            },
                        }}
                    >
                        <MenuItem value="all">Show All Questions</MenuItem>
                        <MenuItem value="answered">Show Answered Questions</MenuItem>
                        <MenuItem value="unanswered">Show Unanswered Questions</MenuItem>
                    </Select>
                    <Button onClick={handleNextPage} disabled={currentPage >= Math.ceil(questions.length / questionsPerPage) - 1}>Next</Button>
                    <Button onClick={handleOpenSubmitDialog}>Submit</Button>
                </div>

                {showSubmitDialog && (
                    <div className="submit-dialog">
                        <div className="dialog-content">
                            <p>Are you sure you want to submit now?</p>
                            <button onClick={handleSubmit}>Yes</button>
                            <button onClick={handleCloseSubmitDialog}>No</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExamQuiz;
