import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Grid, Card, Typography } from '@mui/material';
import NavigationBar from './ccna2/NavigationBar';
import Utilities from '../../utilities/utilities';
import CodeComandConcepts from '../../CodeCommandConcepts';
import BookImg from '../../../assets/book.webp'
import QuizMap from '../../Quizmap';
import { Helmet } from 'react-helmet';


const linksArray = ["Home","Courses", "Calculator"];

const Ccna = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleChapterClick = (chapter) => {
    switch (chapter) {
      case 'CCNA1':
        navigate('/ccna/ccna1/chapter-1');
        break;
      case 'CCNA2':
        navigate('/ccna/ccna2/chapter-1');
        break;
      case 'CCNA3':
        navigate('/ccna/ccna3/chapter-1');
        break;
      default:
        break;
    }
  };
const isHomepage = location.pathname === "/";
  return (
    <>
    {/* Inject global styles to hide the .MuiBox-root.css-73nay0 class */}
    <style>
      {`
        .MuiBox-root.css-73nay0 {
          display: none !important;
        }
      `}
    </style>
    <div
  style={{
    marginTop: isHomepage ? '0px' : '120px',  // Apply 120px margin if isHome is true, else 0px
    marginBottom: '0px',  // This stays the same in both cases
  }}
>
<Helmet>
    <title>CCNA, CCNP and Cyber Security & More | Alpha Centauri Inc.</title>
    <meta
      name="description"
      content="Explore comprehensive educational resources, exam answers, and solutions for Cisco CCNA, CCNP, and more. Access tools like Cisco commands reference, quizzes, and practical networking solutions."
    />
    <meta
      name="keywords"
      content="CCNA ,CCNP, CCNA exam answers, CCNP Answers and quiz"
    />
    <meta
      name="robots"
      content="index, follow"
    />

    {/* Open Graph Meta Tags */}
    <meta property="og:title" content="All Cisco CCNA modules questions and exam answers." />
    <meta property="og:description" content="All Cisco CCNA modules questions and answers." />
    <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
    <meta property="og:url" content="https://alphacentauriinc.com/ccna/ccna" />
    <meta property="og:type" content="website" />


    {/* Canonical Link */}
    {/* <link rel="canonical" href="/ccna/ccna" /> */}

    {/* Structured Data for Article/Tools */}
    <script type="application/ld+json">
      {`
      {
        "@context": "https://schema.org",
        "@type": "WebApplication",
        "name": "Cisco CCNA Modules ",
        "description": "All Cisco CCNA modules questions and exams answers.",
        "url": "https://alphacentauriinc.com/ccna/ccna",
        "logo": "%PUBLIC_URL%/logo192.png",
        "publisher": {
          "@type": "Organization",
          "name": "Alpha Centauri Inc."
        },
        "mainEntityOfPage": "https://alphacentauriinc.com/ccna/ccna"
      }
        `}
    </script>

  </Helmet>
      <NavigationBar links={linksArray}  />
      <div style={{ marginTop: '25px' , marginBottom: '0px'}}></div>

      <Container sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', padding: '16px', borderRadius: '8px', }}>
        <Typography variant={isHomepage ? "h5" : "h5"}  // Different variant for "Hi" and "Welcome to CCNA"
      color={isHomepage ? "#ffffff" : "#1976d2" } // Different color for "Hi" and "Welcome to CCNA"
       gutterBottom>
          {isHomepage ? 'Explore Cisco Certified Network Associate (CCNA) Exam Answers & Practice Quiz for Success' : 'CCNA Exam Questions & Answers with Practice Quizzes for Exam Success'}
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <Card
              variant="outlined"
              sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 }}}
              onClick={() => handleChapterClick('CCNA1')}
              style={{ cursor: 'pointer', padding: '16px' }}
              
              
            >
              <Typography level="title-md" sx={{ color: '#1976d2', fontSize: '1rem', textAlign: 'center' }}><img src={BookImg} alt="" /> CCNA 1</Typography>
              <Typography level="body-sm" sx={{ color: '#1976d2', textAlign: 'center', marginTop: '8px' }}>
                Introduction to Networking Concepts and Protocols.
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              variant="outlined"
              sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 } }}
              onClick={() => handleChapterClick('CCNA2')}
              style={{ cursor: 'pointer', padding: '16px' }}
            >
              <Typography level="title-md" sx={{ color: '#1976d2', fontSize: '1rem', textAlign: 'center' }}><img src={BookImg} alt="" /> CCNA 2</Typography>
              <Typography level="body-sm" sx={{ color: '#1976d2', textAlign: 'center', marginTop: '8px' }}>
                Deep Dive into Network Access and Configuration.
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
              variant="outlined"
              sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 } }}
              onClick={() => handleChapterClick('CCNA3')}
              style={{ cursor: 'pointer', padding: '16px' }}
            >
              <Typography level="title-md" sx={{ color: '#1976d2', fontSize: '1rem', textAlign: 'center' }}><img src={BookImg} alt="" /> CCNA 3</Typography>
              <Typography level="body-sm" sx={{ color: '#1976d2', textAlign: 'center', marginTop: '8px' }}>
              {isHomepage ? 'Advanced Networking Concepts and Execution.' :  'Advanced Networking Concepts and Implementation.'}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <QuizMap />
<CodeComandConcepts/>
      {/* Add margin to the Utilities component */}
      <div style={{ marginTop: '0px' , marginBottom: '0px'}}>
        <Utilities />
      </div>
    </div>
    </>
  );
};

export default Ccna;
