import React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; // Import Grid from Material-UI

//import { Grid, Card, Typography, Container, AspectRatio } from '@mui/material';
import { Link, useLocation, useRoutes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavigationBar from './courses/ccna/ccna2/NavigationBar';
import QuizImg from '../assets/quiz.png';
const QuizMap = ({ showNav = true }) => {
  const location = useLocation(); // Get the current location
  const { t } = useTranslation();

  // Define the base route for quizzes (you can adjust this for your structure)
  const baseQuizPath = '/ccna'; 

  // Define the possible quizzes by searching through routes
  const quizRoutes = [
    '/ccna/ccna1/ccna1_quiz/QuizApp',
    '/ccna/ccna2/ccna2_quiz/QuizApp',
    '/ccna/ccna3/ccna3_quiz/QuizApp',
    // Add more routes as they are created
  ];

  // Check if the current path matches any of the quiz paths
  const isQuizPath = quizRoutes.some(route => location.pathname.includes(route));

  // Define your links here or dynamically extract from your current route configuration
  const links = ['Home', 'Courses', 'Calculators'];

  // Dynamically create the quiz layout based on the existing quiz routes
  const quizLinks = quizRoutes.map(route => {
    // Assuming each path corresponds to a "QuizApp" that is rendered at that route
    return {
      path: route,
      title: route.split('/').pop(), // Grab last segment of path as quiz title (can be customized)
      image: QuizImg, // Customize or set dynamic image
      description: `Test your knowledge for ${route.split('/')[2]} Quiz`
    };
  });

  return (
    <div>
      {showNav && <NavigationBar links={links} />}

      <Container sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', padding: '16px', borderRadius: '8px', marginTop: '50px' }}>
        <Typography variant="h4" color="#1976d2" gutterBottom ="h2">
          {isQuizPath ? 'Quiz Selection' : 'Explore Cisco CCNA Practice Quizzes for Exam Preparation & Certification Success'}
        </Typography>

        <Grid container spacing={2}>
          {quizLinks.map((quiz, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card variant="outlined" sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 } }}>
                <Link to={quiz.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <AspectRatio sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', '& .MuiAspectRatio-content': { backgroundColor: 'rgba(17, 14, 14, 0.6)' } }}>
                    <img src={quiz.image} alt={quiz.title} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                  </AspectRatio>
                  <div>
                    <Typography level="title-md" sx={{ color: '#1976d2' }}>{quiz.title}</Typography>
                    <Typography level="body-sm" sx={{ color: '#1976d2' }}>{quiz.description}</Typography>
                  </div>
                </Link>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <div style={{ marginTop: '0px' , marginBottom: '0px'}}>
</div>
    </div>
  );
};

export default QuizMap;
