import React from 'react';
import { Box, Typography, Button, useTheme, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  // Image source for 404 error, could be a general or themed GIF.
  const gifSrc = '/404-b.gif';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: isDarkMode ? '#121212' : '#f5f5f5', // Dark or light mode background
        color: isDarkMode ? '#ffffff' : '#000000',
        padding: theme.spacing(2),
        textAlign: 'center',
      }}
    >
      <Typography variant="h2" gutterBottom color="text.primary">
        404 - Page Not Found
      </Typography>

      <Box
        sx={{
          width: '100%',
          maxWidth: '600px',
          height: 'auto',
          mb: 4,
          position: 'relative',
        }}
      >
        <Box
          component="img"
          src={gifSrc}
          alt="404 Animation"
          sx={{
            width: '100%',
            height: 'auto',
            display: 'block',
            position: 'relative',
            zIndex: 1,
          }}
        />
      </Box>

      <Typography variant="h5" gutterBottom color="text.primary">
        You are not disconnected, you just made a wrong turn.
      </Typography>
      
      <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
        Don't worry, we’ll help you get back on track. Choose one of the options below to get back to the right path.
      </Typography>

      {/* Navigation Links */}
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Button
            component={Link}
            to="/ccna/ccna"
            variant="outlined"
            color="primary"
            sx={{ px: 4 }}
          >
            Courses
          </Button>
        </Grid>
        <Grid item>
          <Button
            component={Link}
            to="/ccna/calculator/calculators"
            variant="outlined"
            color="primary"
            sx={{ px: 4 }}
          >
            Calculator
          </Button>
        </Grid>
        <Grid item>
          <Button
            component={Link}
            to="/ccna/ccna1/ccna1_quiz/QuizApp"
            variant="outlined"
            color="primary"
            sx={{ px: 4 }}
          >
            Take a Quiz
          </Button>
        </Grid>
        <Grid item>
        <Button
          component={Link}
          to="/"
          variant="contained"
          color="primary"
          sx={{ px: 4 }}
        >
          Home
        </Button>
        </Grid>
      </Grid>

      {/* <Box sx={{ mt: 4 }}>
        <Button
          component={Link}
          to="/"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Go Back Home
        </Button>
      </Box> */}
    </Box>
  );
};

export default PageNotFound;
