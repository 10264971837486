// // QuizApp.jsx
// import ChapterQuiz from './ChapterQuiz';
// import ScrollToTop from '../../../../../ScrolltopPage';
// import ExamQuiz from './ExamQuiz';
// import { QUIZ_CONCEPTS } from './Concept_Data'; // Importing the quiz concepts array
// import './QuizConcept.css'; // Importing the CSS for styling
// import React, { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// //import QuizNavbar from './QuizNavbar';
// import { useTranslation } from 'react-i18next';


// const QuizApp = () => {
//   const {t} = useTranslation();
//   const [quizMode, setQuizMode] = useState(null); // To track the selected quiz mode
//   const [searchTerm, setSearchTerm] = useState('');
//   const navigate = useNavigate();
//   const location = useLocation();

//   // List of chapters for the navbar
//   const chapters = [
//     { name: t('Chapter 1'), path: '/ccna/CCNA2/chapter-1', key: 'chapter1' },
//     { name: t('Chapter 2'), path: '/ccna/CCNA2/chapter-2', key: 'chapter2' },
//     { name: t('Chapter 3'), path: '/ccna/CCNA2/chapter-3', key: 'chapter3' },
//     { name: t('Chapter 4'), path: '/ccna/CCNA2/chapter-4', key: 'chapter4' },
//     { name: t('Chapter 5'), path: '/ccna/CCNA2/chapter-5', key: 'chapter5' },
//     { name: t('Chapter 6'), path: '/ccna/CCNA2/chapter-6', key: 'chapter6' },
//     { name: t('Chapter 7'), path: '/ccna/CCNA2/chapter-7', key: 'chapter7' },
//     { name: t('Chapter 8'), path: '/ccna/CCNA2/chapter-8', key: 'chapter8' },
//     { name: t('Chapter 9'), path: '/ccna/CCNA2/chapter-9', key: 'chapter9' },
//     { name: t('Chapter 10'), path: '/ccna/CCNA2/chapter-10', key: 'chapter10' },
//     { name: t('Chapter 10 Exam'), path: '/ccna/CCNA2/chapter-10-Exam', key: 'chapter10Exam' },
//     { name: t('Exam'), path: '/ccna/CCNA2/Exam', key: 'CCNA2Exam' },
//     { name: t('Exam Version 7'), path: '/ccna/CCNA2/Exam-Version-7', key: 'CCNA2Exam2024' },
//   ];


//   const currentChapter = chapters.find((chapter) => chapter.path === location.pathname);

//   const handleSearch = (term) => {
//     setSearchTerm(term);
//   };

//   const handleChapterChange = (event) => {
//     const selectedChapter = event.target.value;
//     navigate(selectedChapter);
//   };

//   const handleNextChapter = () => {
//     const currentIndex = chapters.findIndex((chapter) => chapter.key === currentChapter?.key);
//     if (currentIndex < chapters.length - 1 && currentIndex >= 0) {
//       navigate(chapters[currentIndex + 1].path);
//     }
//   };

//   const handlePreviousChapter = () => {
//     const currentIndex = chapters.findIndex((chapter) => chapter.key === currentChapter?.key);
//     if (currentIndex > 0) {
//       navigate(chapters[currentIndex - 1].path);
//     }
//   };

//   const handleModeSelection = (mode) => {
//     setQuizMode(mode);
//    // navigate(mode); // Navigate to the selected quiz mode
//   };

//   const getArrayOrString = (key) => {
//     const value = t(key, { returnObjects: true });
//     return Array.isArray(value) ? value : [value];  // Return as an array if it's not already
// };

//   return (
//     <div>
//       <ScrollToTop />
//       {/* <QuizNavbar
//         chapters={chapters}
//         onSearch={handleSearch}
//         onChapterChange={handleChapterChange}
//         onNextChapter={handleNextChapter}
//         onPreviousChapter={handlePreviousChapter}
//       /> */}
//       <div className="quiz-app"><h1>CCNA1 Quiz</h1></div>
//       {!quizMode ? (
//         <div className="mode-selection">
//           {QUIZ_CONCEPTS.map((concept, index) => (
//             <div key={index} className="quiz-concept-card" onClick={() => handleModeSelection(concept.route)}>
//               <img src={concept.image} alt={concept.title} className="card-image" />
//               <h2 className="card-title">{concept.title}</h2>
//               <p className="card-description">{concept.description}</p>
//             </div>
//           ))}
//         </div>
//       ) : (
//         <div>
//           {quizMode === 'ccna/CCNA1/ccna1_quiz/ExamMode' && <ExamQuiz />}
//           {quizMode === 'ccna/CCNA1/ccna1_quiz/ChapterQuiz' && <ChapterQuiz />}
//         </div>
//       )}
//     </div>
//   );
// };

// export default QuizApp;



import ChapterQuiz from './ChapterQuiz';
import ScrollToTop from '../../../../../ScrolltopPage';
import ExamQuiz from './ExamQuiz';
import { QUIZ_CONCEPTS } from './Concept_Data'; // Importing the quiz concepts array
import './QuizConcept.css'; // Importing the CSS for styling
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // useNavigate for navigation
import NavigationBar from '../../ccna2/NavigationBar';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';


const QuizApp = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const linksArray = ["Home", "Courses", "Calculator"];

  // List of chapters for the navbar
  const chapters = [
    { name: t('Chapter 1'), path: '/ccna/ccna3/chapter-1', key: 'chapter1' },
    { name: t('Chapter 2'), path: '/ccna/ccna3/chapter-2', key: 'chapter2' },
    { name: t('Chapter 3'), path: '/ccna/ccna3/chapter-3', key: 'chapter3' },
    { name: t('Chapter 4'), path: '/ccna/ccna3/chapter-4', key: 'chapter4' },
    { name: t('Chapter 5'), path: '/ccna/ccna3/chapter-5', key: 'chapter5' },
    { name: t('Chapter 6'), path: '/ccna/ccna3/chapter-6', key: 'chapter6' },
    { name: t('Chapter 7'), path: '/ccna/ccna3/chapter-7', key: 'chapter7' },
    { name: t('Chapter 8'), path: '/ccna/ccna3/chapter-8', key: 'chapter8' },
    { name: t('Chapter 9'), path: '/ccna/ccna3/chapter-9', key: 'chapter9' },
    { name: t('Chapter 10'), path: '/ccna/ccna3/chapter-10', key: 'chapter10' },
    // { name: t('Chapter 10 Exam'), path: '/ccna/ccna2/chapter-10-Exam', key: 'chapter10Exam' },
    // // { name: t('CCNA2 Exam'), path: '/ccna/CCNA2/chapter-10-Exam', key: 'CCNA2Exam' },
    // { name: t('Exam'), path: '/ccna/ccna2/Exam', key: 'CCNA2Exam' },
    // { name: t('Exam Version 7'), path: '/ccna/ccna2/Exam-Version-7', key: 'CCNA2Exam2024' },
    { name: t('Quiz'), path: '/ccna/ccna3/ccna3_quiz/QuizApp' }  ];

  const currentChapter = chapters.find((chapter) => chapter.path === location.pathname);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleChapterChange = (event) => {
    const selectedChapter = event.target.value;
    navigate(selectedChapter);
  };

  const handleNextChapter = () => {
    const currentIndex = chapters.findIndex((chapter) => chapter.key === currentChapter?.key);
    if (currentIndex < chapters.length - 1 && currentIndex >= 0) {
      navigate(chapters[currentIndex + 1].path);
    }
  };

  const handlePreviousChapter = () => {
    const currentIndex = chapters.findIndex((chapter) => chapter.key === currentChapter?.key);
    if (currentIndex > 0) {
      navigate(chapters[currentIndex - 1].path);
    }
  };

  // Ensure the route passed to navigate is an absolute path
  const handleModeSelection = (mode) => {
    if (!mode.startsWith('/')) {
      mode = '/' + mode;
    }
    navigate(mode); // Navigate to the selected quiz mode route
  };

  const [quizTitle, setQuizTitle] = useState('');

  useEffect(() => {
    // Extract the last segment of the URL path
    const path = window.location.pathname.split('/');
    const ccnaPath = path[2]; // URL structure like "/ccna/ccna1/quiz"

    // Set the quiz title based on the URL segment
    switch(ccnaPath.toLowerCase()) {
      case 'ccna1':
      case 'ccna-1':
        setQuizTitle('CCNA 1 Quiz');
        break;
      case 'ccna2':
      case 'ccna-2':
        setQuizTitle('CCNA 2 Quiz');
        break;
      case 'ccna3':
      case 'ccna-3':
        setQuizTitle('CCNA 3 Quiz');
        break;
      default:
        setQuizTitle('CCNA Quiz'); // Default title if no specific match
        break;
    }
  }, []); // Empty dependency array so it runs once on component mount

  return (
    <div>
          <Helmet>
      <title>
    CCNA 3 Chapter Quiz & Exam Practice Quiz | Alpha Centauri Inc.
  </title>
  
  <meta
    name="description"
    content="Prepare for your CCNA 3 exam with our practice quizzes. Test your knowledge on networking concepts, routing, switching, and more. Get instant feedback and improve your CCNA skills."
  />
  
  <meta
    name="keywords"
    content="CCNA 3 quiz, CCNA practice test, networking quiz, exam practice, CCNA exam, routing quiz, switching quiz, network security quiz, CCNA test preparation"
  />
      <meta
      name="robots"
      content="index, follow"
    />

    {/* Open Graph Meta Tags */}
    <meta property="og:title" content="All Cisco CCNA modules questions and exam answers." />
    <meta property="og:description" content="All Cisco CCNA modules questions and answers." />
    <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
    <meta property="og:url" content="https://alphacentauriinc.com/ccna/ccna3/ccna3_quiz/QuizApp" />
    <meta property="og:type" content="website" />


    {/* Canonical Link */}
    <link rel="canonical" href="/ccna/ccna3/ccna3_quiz/QuizApp" />

    {/* Structured Data for Article/Tools */}
    <script type="application/ld+json">
      {`
      {
        "@context": "https://schema.org",
        "@type": "WebApplication",
        "name": "Cisco CCNA 3 Modules Quizzes ",
        "description": "All Cisco CCNA 3 modules practice quiz.",
        "url": "https://alphacentauriinc.com/ccna/ccna3/ccna3_quiz/QuizApp",
        "logo": "%PUBLIC_URL%/logo192.png",
        "publisher": {
          "@type": "Organization",
          "name": "Alpha Centauri Inc."
        },
        "mainEntityOfPage": "https://alphacentauriinc.com/ccna/ccna3/ccna3_quiz/QuizApp"
      }
        `}
    </script>

  </Helmet>
      <ScrollToTop />
      <NavigationBar links={linksArray}  />

      <div className="quiz-app">
      <h1>{quizTitle}</h1>
    </div>
      {/* <div className="quiz-app"><h1>CCNA1 Quiz</h1></div> */}
      <div className="mode-selection">
        {QUIZ_CONCEPTS.map((concept, index) => (
          <div
            key={index}
            className="quiz-concept-card"
            onClick={() => handleModeSelection(concept.route)} // Navigate on click
          >
            <img src={concept.image} alt={concept.title} className="card-image" />
            <h2 className="card-title">{concept.title}</h2>
            <p className="card-description">{concept.description}</p>
          </div>
        ))}
        
      </div>
              {/* Add margin to the Utilities component */}
              <div style={{ marginTop: '60px' , marginBottom: '60px'}}></div>

    </div>
  );
};

export default QuizApp;

