import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link, useParams } from 'react-router-dom';
import WatermarkedImage from '../../../WatermarkedImage';
import StudyRobot from '../../../StudyRobot';
import '../ccna1/CcnaQuestionCard.css';
import CCNA3_Quiz_Bank from '../../../Settings/locales/en/ccna3_translation.json'
import { useTranslation } from 'react-i18next';
import ChapterNavigation from '../ccna1/ChapterNav';
import Q38 from '../ccna1/chapter-2/ccna1_chapter2_assets/Q13.jpg';
import Q1V7 from '../../../../assets/CCNA2ExamVersion7/Q1.jpg';
import Q2V7 from '../../../../assets/CCNA2ExamVersion7/Q2.png';
import Q3V7 from '../../../../assets/CCNA2ExamVersion7/Q3.png';
import Q5V7 from '../../../../assets/CCNA2ExamVersion7/Q5.png';
import Q9V7 from '../../../../assets/CCNA2ExamVersion7/Q9.png'; 
import Q14V7 from '../../../../assets/CCNA2ExamVersion7/Q14.png';
import Q17V7 from '../../../../assets/CCNA2ExamVersion7/Q17.png';
import Q21V7 from '../../../../assets/CCNA2ExamVersion7/Q21.png';
import Q23V7 from '../../../../assets/CCNA2ExamVersion7/Q23.png';
import Q25V7 from '../../../../assets/CCNA2ExamVersion7/Q25.png';
import Q29V7 from '../../../../assets/CCNA2ExamVersion7/Q29.png';
import Q32V7 from '../../../../assets/CCNA2ExamVersion7/Q32.png';
import Q42V7 from '../../../../assets/CCNA2ExamVersion7/Q42.png';
import Q44V7 from '../../../../assets/CCNA2ExamVersion7/Q44.png';
import Q53V7 from '../../../../assets/CCNA2ExamVersion7/Q53.png';
import Q54V7 from '../../../../assets/CCNA2ExamVersion7/Q54.png';
import Q56V7 from '../../../../assets/CCNA2ExamVersion7/Q56.png';
import Q57V7 from '../../../../assets/CCNA2ExamVersion7/Q57.png';
import Q61V7 from '../../../../assets/CCNA2ExamVersion7/Q61.png';
import Q64V7 from '../../../../assets/CCNA2ExamVersion7/Q64.png';
import Q66V7 from '../../../../assets/CCNA2ExamVersion7/Q66.png';
import Q67V7 from '../../../../assets/CCNA2ExamVersion7/Q67.png';
import Q74V7 from '../../../../assets/CCNA2ExamVersion7/Q74.png';
import Q87V7 from '../../../../assets/CCNA2ExamVersion7/Q87.png';
import Q91V7 from '../../../../assets/CCNA2ExamVersion7/Q91.png';
import Q95V7 from '../../../../assets/CCNA2ExamVersion7/Q95.png';
import Q96V7 from '../../../../assets/CCNA2ExamVersion7/Q96.png';
import Q99V7 from '../../../../assets/CCNA2ExamVersion7/Q99.png';
import Q112V7 from '../../../../assets/CCNA2ExamVersion7/Q112.png';
import Q115V7 from '../../../../assets/CCNA2ExamVersion7/Q115.png';
import Q118V7 from '../../../../assets/CCNA2ExamVersion7/Q118.png';
import Q124V7 from '../../../../assets/CCNA2ExamVersion7/Q124.png';
import Q127V7 from '../../../../assets/CCNA2ExamVersion7/Q127.png';
import Q130V7 from '../../../../assets/CCNA2ExamVersion7/Q130.png';
import Q141V7 from '../../../../assets/CCNA2ExamVersion7/Q141.png';
import Q146V7 from '../../../../assets/CCNA2ExamVersion7/Q146.png';
import NavigationBar from '../ccna2/NavigationBar';
import CircularWithValueLabel from '../../../Loader';
import ImageViewer from '../../../ImageViewer';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import ImageEnhancer from '../../../ImageEnhancer';
import { Helmet } from 'react-helmet';

// Helper function to detect Apple devices
const isAppleDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return /macintosh|mac os x|iphone|ipod|ipad/i.test(userAgent);
};

const images = {
Q1V7, Q2V7, Q3V7, Q5V7, Q9V7, Q14V7, Q17V7, Q21V7, Q23V7, Q25V7, Q29V7, Q32V7, Q42V7,
Q44V7, Q53V7, Q54V7, Q56V7, Q57V7, Q61V7, Q64V7, Q66V7, Q67V7, Q74V7, Q87V7, Q91V7,
Q95V7, Q96V7, Q99V7, Q112V7, Q115V7, Q118V7, Q124V7, Q127V7, Q130V7, Q141V7, Q146V7
  // Add more images here as needed
};

const linksArray = ["Home","Courses", "Calculator"];

const QuestionCard = ({ questionData, searchTerm, isHighlighted }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation('ccna3');

    // Apply the highlight effect when the card is highlighted
    useEffect(() => {
      const cardElement = document.getElementById(`question-${questionData.id}`);
  
      if (isHighlighted && cardElement) {
        cardElement.classList.add('highlighted');
  
        // Remove the highlight after 6 seconds
        setTimeout(() => {
          cardElement.classList.remove('highlighted');
        }, 10000);
      }
    }, [isHighlighted, questionData.id]); // Re-run the effect when isHighlighted or questionData.id changes  


  useEffect(() => {
    setTimeout(() => setIsLoading(false), 3000); // Simulate loading
  }, []);

  const highlightText = (text, term) => {
    if (!term) return text;
    const regex = new RegExp(`(${term})`, 'gi');
    return text.replace(regex, (match) => `<mark style="background-color: yellow; color: black;">${match}</mark>`);
  };

  const renderMatchingQuestion = (question) => {
    return (
      <div className="matching-question">
        <div className="matching-container">
          <div  className="matching-items">
            {question.items.map((item) => (
              <div key={item.id} className="matching-item">
                <span>{item.content}</span>
              </div>
            ))}
          </div>
          <div className="matching-options">
            {question.options.map((option) => (
              <div style={{background: 'rgba( 58, 1, 114, 0.7 )'}} key={option.id} className="matching-option">
                <span>{option.content}</span>
              </div>
            ))}
          </div>
        </div>
        <h4>Correct Matches:</h4>
<div className="correct-matches">
  {question.items.map((item) => (
    <div key={item.id} className="correct-match-item">
      <div className="match-content">
        <span className="item-content">{item.content}</span>
        <span className="match-arrow">→</span>
        <div className="matched-options">
          {(() => {
            const correctMatch = question.correctMatches[item.id];
            if (Array.isArray(correctMatch)) {
              return correctMatch.map((optionId) => {
                const option = question.options.find(opt => opt.id === optionId);
                return (
                  <div key={optionId} className="correct-option">
                    {option.content} ✔
                  </div>
                );
              });
            } else if (typeof correctMatch === 'string') {
              const option = question.options.find(opt => opt.id === correctMatch);
              return (
                <div key={correctMatch} className="correct-option">
                  {option.content} ✔
                </div>
              );
            } else {
              return null;
            }
          })()}
        </div>
      </div>
      <div className="match-line"></div>
    </div>
  ))}
</div>
</div>
    );
  };

     // Only render the loading spinner when not on Apple devices
     const shouldRenderLoading = !isAppleDevice() && isLoading;

  return (
    <React.Fragment>
      
      <CardContent sx={{width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
        <div className={`card ${isHighlighted? 'highlighted' : ''}`}
        >
        {shouldRenderLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
              <CircularWithValueLabel />
            </Box>
          ) : (
            <>
              <h3>{t('Question')} {questionData.id}</h3>
              <p
                style={{ fontWeight: 'bold' }}
                dangerouslySetInnerHTML={{
                  __html: highlightText(questionData.question, searchTerm)
                }}
              />
              {questionData.image && images[questionData.image] && (
                <ImageEnhancer
                  src={images[questionData.image]}
                  watermarkText="AC Studies"
                  maxWidth={400}
                />
              )}
              {questionData.type === 'multiple-choice' ? (
                <ol>
                  {questionData.answers.map((answer, index) => (
                    <li key={index} className={answer.isCorrect ? 'correct' : ''}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: highlightText(answer.text, searchTerm)
                        }}
                      />
                      {answer.isCorrect && <span className="checkmark">✔</span>}
                    </li>
                  ))}
                </ol>
              ) : questionData.type === 'matching' ? (
                renderMatchingQuestion(questionData)
              ) : (
                <p>Unsupported question type: {questionData.type}</p>
              )}
              <div className="reason">
                <p>{t('Explanation')}: {questionData.reason}</p>
              </div>
            </>
          )}
        </div>
      </CardContent>
    </React.Fragment>
  );
};

const QuestionList = ({ q }) => {
  const [highlightedQuestionId, setHighlightedQuestionId] = useState(null);
  const [canonicalUrls, setCanonicalUrls] = useState({}); // To store canonical URLs for each question
  const canonicalLinks = require('../../../../canonicalLinks.json'); // Load the canonical links JSON
  const firstOccurrenceMap = {}; // To track the first occurrence of each question

  const { t } = useTranslation('ccna3');
  const [searchTerm, setSearchTerm] = useState('');
  const [matchingQuestion, setMatchingQuestion] = useState(null); // State to store the matched question
  const navigate = useNavigate();
  const location = useLocation();
  const { questionSlug } = useParams(); // Get the question slug from URL

  const chapters = [
    { name: t('Chapter 1'), path: '/ccna/ccna3/chapter-1', key: 'chapter1' },
    { name: t('Chapter 2'), path: '/ccna/ccna3/chapter-2', key: 'chapter2' },
    { name: t('Chapter 3'), path: '/ccna/ccna3/chapter-3', key: 'chapter3' },
    { name: t('Chapter 4'), path: '/ccna/ccna3/chapter-4', key: 'chapter4' },
    { name: t('Chapter 5'), path: '/ccna/ccna3/chapter-5', key: 'chapter5' },
    { name: t('Chapter 6'), path: '/ccna/ccna3/chapter-6', key: 'chapter6' },
    { name: t('Chapter 7'), path: '/ccna/ccna3/chapter-7', key: 'chapter7' },
    { name: t('Chapter 8'), path: '/ccna/ccna3/chapter-8', key: 'chapter8' },
    { name: t('Chapter 9'), path: '/ccna/ccna3/chapter-9', key: 'chapter9' },
    { name: t('Chapter 10'), path: '/ccna/ccna3/chapter-10', key: 'chapter10' },
    // { name: t('Chapter 10 Exam'), path: '/ccna/ccna3/chapter-10-Exam', key: 'chapter10Exam' },
    // { name: t('CCNA2 Exam'), path: '/ccna/CCNA2/chapter-10-Exam', key: 'CCNA2Exam' },
    // { name: t('Exam'), path: '/ccna/CCNA2/Exam', key: 'CCNA2Exam' },
    // { name: t('Exam Version 7'), path: '/ccna/CCNA2/Exam-Version-7', key: 'CCNA2Exam2024' },
    { name: t('Quiz'), path: '/ccna/ccna3/ccna3_quiz/QuizApp' }
  ];


  // Function to generate URL-friendly slug
  const generateSlug = (text) => {
    if (text == null) {
      return '';
    }
    const stringText = String(text);
    return stringText
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9\s-]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const matchChapter = (pathname, chapters) => {
    const normalizedPath = pathname.toLowerCase().replace(/\/+$/, '').split('/').slice(0, 4).join('/');
    const chapterPattern = /chapter-(\d+)/i;
    const examPattern = /exam(?:-(\d+))?/i;
    const specialExamPattern = /exam-version-(\d+)/i;

    const cleanPath = (str) => str.toLowerCase().replace(/[^a-z0-9\-]/g, '');

    const matchingStrategies = [
      (chapter) => normalizedPath === chapter.path.toLowerCase(),
      (chapter) => {
        const pathMatch = normalizedPath.match(chapterPattern);
        const chapterMatch = chapter.path.match(chapterPattern);
        if (pathMatch && chapterMatch) {
          return parseInt(pathMatch[1], 10) === parseInt(chapterMatch[1], 10);
        }
        return false;
      },
      (chapter) => {
        const cleanChapterPath = cleanPath(chapter.path);
        const cleanNormalizedPath = cleanPath(normalizedPath);
        return cleanNormalizedPath.includes(cleanChapterPath) || cleanChapterPath.includes(cleanNormalizedPath);
      },
      (chapter) => {
        const pathMatch = normalizedPath.match(examPattern);
        const chapterMatch = chapter.path.match(chapterPattern);
        if (pathMatch && chapterMatch) {
          const chapterNumber = parseInt(pathMatch[1], 10);
          return chapterNumber === parseInt(chapterMatch[1], 10);
        }
        return false;
      },
      (chapter) => {
        const pathMatch = normalizedPath.match(specialExamPattern);
        const chapterMatch = chapter.path.match(chapterPattern);
        if (pathMatch && chapterMatch) {
          const examVersion = parseInt(pathMatch[1], 10);
          return examVersion === parseInt(chapterMatch[1], 10);
        }
        return false;
      },
      (chapter) => {
        const cleanChapterName = cleanPath(chapter.name);
        const cleanNormalizedPath = cleanPath(normalizedPath);
        return cleanNormalizedPath.includes(cleanChapterName) || cleanChapterName.includes(cleanNormalizedPath);
      }
    ];

    for (const strategy of matchingStrategies) {
      const matchedChapter = chapters.find(strategy);
      if (matchedChapter) return matchedChapter;
    }

    const lenientMatch = chapters.find(chapter =>
      normalizedPath.includes(chapter.path.toLowerCase()) ||
      chapter.path.toLowerCase().includes(normalizedPath)
    );

    return lenientMatch || null;
  };

  // Function to get canonical URL from the JSON based on questionSlug
  const getCanonicalUrl = (questionSlug) => {
    const foundLink = canonicalLinks.find(link => link.questionSlug === questionSlug);
    return foundLink ? foundLink.canonicalUrl : currentChapter.path; // Fallback to current chapter path if not found
  };

  const currentChapter = matchChapter(location.pathname, chapters);
  const currentChapterName = currentChapter ? currentChapter.name : '';

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleChapterChange = (event) => {
    const selectedChapter = event.target.value;
    navigate(selectedChapter);
    clearSearch();
  };

  const handleNextChapter = () => {
    const currentIndex = chapters.findIndex((chapter) => chapter.key === currentChapter?.key);
    if (currentIndex < chapters.length - 1 && currentIndex >= 0) {
      navigate(chapters[currentIndex + 1].path);
    }
  };

  const handlePreviousChapter = () => {
    const currentIndex = chapters.findIndex(chapter => chapter.key === currentChapter?.key);
    if (currentIndex > 0) {
      navigate(chapters[currentIndex - 1].path);
    }
  };

  const currentQuestions = CCNA3_Quiz_Bank[currentChapter?.key] || [];

  useEffect(() => {
    if (questionSlug) {
      const matchingQuestion = currentQuestions.find(
        (q) => generateSlug(q.question) === questionSlug
      );

      if (matchingQuestion) {
        // Update page title and meta description for SEO
        document.title = `${t('Question')} ${matchingQuestion.id}: ${matchingQuestion.question}`;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute("content", matchingQuestion.reason);
        }

        // Set the canonical URL dynamically based on the questionSlug
        const canonicalUrl = getCanonicalUrl(questionSlug);

        // Update canonical link in the head
        const linkTag = document.querySelector('link[rel="canonical"]');
        if (linkTag) {
          linkTag.setAttribute("href", canonicalUrl);
        } else {
          const newLinkTag = document.createElement('link');
          newLinkTag.setAttribute("rel", "canonical");
          newLinkTag.setAttribute("href", canonicalUrl);
          document.head.appendChild(newLinkTag);
        }

        // Scroll to the matching question and highlight it
        setTimeout(() => {
          const element = document.getElementById(`question-${matchingQuestion.id}`);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setHighlightedQuestionId(matchingQuestion.id);
          }
        }, 100);
      } else {
        navigate('/404', { replace: true });
      }
    }
  }, [questionSlug, currentQuestions, t]);

  
  return (
    <div>
            <Helmet>
        <title>
          {currentChapter
            ? `${currentChapter.name} - CCNA 3`
            : 'CCNA 3'}
          {questionSlug ? ` - Question: ${questionSlug}` : ''}
        </title>
        <meta
          name="description"
          content={
            questionSlug
              ? `Explore the CCNA 3 chapter: ${currentChapter.name} and learn about ${questionSlug}.`
              : `Explore CCNA 3 questions and answers in the ${currentChapter ? currentChapter.name : 'chapters'}.`
          }
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <NavigationBar 
        links={linksArray} 
        chapters={chapters} 
        onSearch={handleSearch}
        currentChapter={currentChapter}
        currentChapterName={currentChapterName}
        searchTerm={searchTerm}
        clearSearch={clearSearch}
      />
      <div className="current-chapter-container">
                <h1
                    style={{
                        color: 'white', // Change text color
                        fontSize: '24px', // Set font size
                        fontWeight: 'bold', // Make it bold
                        textAlign: 'center', // Center the text
                        margin: '10px 0', // Add margin for spacing
                    }}
                >
                    {currentChapter ? (
                        <span style={{textAlign: 'center'}}>
                          CCNA 3: {currentChapter.name}
                         </span>
                    ) : null}
                </h1>
            </div>

            <div>
        {currentQuestions.length > 0 ? (
          currentQuestions
            .filter((qa) => qa.question.toLowerCase().includes(searchTerm.toLowerCase()))
            .map((qa) => (
              <Link
                key={qa.id}
                to={`${currentChapter.path}/${generateSlug(qa.question)}`}
                style={{ textDecoration: 'none' }}
              >
                <div
                  id={`question-${qa.id}`}
                  className="question"
                  style={{
                    transition: 'background-color 0.3s ease-out',
                  }}
                >
                  <QuestionCard
                    questionData={qa}
                    searchTerm={searchTerm}
                    isHighlighted={qa.id === highlightedQuestionId} // Pass highlighted state
                  />
                </div>
              </Link>
          ))
      ) : (
        <p>No questions found for this chapter.</p>
      )}
      </div>

      <ChapterNavigation
        chapters={chapters}
        currentChapter={currentChapter}
        handleChapterChange={handleChapterChange}
        handleNextChapter={handleNextChapter}
        handlePreviousChapter={handlePreviousChapter}
      />
      <StudyRobot />
    </div>
  );
};

export default QuestionList;