import ExamImg from '../../../../../assets/exam.webp';
import propsImg from '../../../../../assets/config.png';
import QuizImg from '../../../../../assets/quiz.webp';
import stateImg from '../../../../../assets/state-mgmt.png';
export const QUIZ_CONCEPTS = [
    {
      image: ExamImg,
      title: 'Exam Quiz',
     description:
     '',
       route:'ccna/ccna2/ccna2_quiz/ExamQuiz',
    },
    {
      image: QuizImg,
      title: 'Chapter Quiz',
    /* description:
     'Return (potentially dynamic) HTML(ish) code to define the actual markup that will be rendered.',*/
     route:'ccna/ccna2/ccna2_quiz/ChapterQuiz',
    },

   
];
