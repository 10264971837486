import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { Container, Box, Button} from "@mui/material";
import './NewFooter.css'

// Access version number from the environment variable
const version = process.env.REACT_APP_VERSION;

export default function NewFooter(){
return(
    <footer className="footer">

        <div className="cotainer">
         <div className="row">
            <div className="footer-col">
             <h4>Company</h4>
             <ul>
                <li><a href="#">about us</a></li>
                <li><a href="#">services</a></li>
                <li><Link to="/sitemap">site map</Link></li>
                {/* <li><Link to="/sitemap"><Button variant="text">site map</Button></Link></li> */}
                </ul>   
            </div>
            <div className="footer-col">
             <h4>Solutions</h4>
             <ul>
                <li><a href="#">Labs</a></li>
                <li><Link to="/Quizmap">quiz</Link></li>
                <li><a href="#">Downloads</a></li>
                <li><a href="#">Videos</a></li>
                </ul>   
            </div>
            <div className="footer-col">
             <h4>Legal</h4>
             <ul>
                <li><a href="#">Privacy</a></li>
                <li><a href="#">Terms</a></li>

                </ul>   
            </div>
            <div className="footer-col">
             <h4>Socials</h4>
             <div className="social-links">
             <div className="social-links">
        <a href="#" aria-label="Visit Facebook">
            <i className="fab fa-facebook-f"></i>
        </a>
        <a href="#" aria-label="Visit Twitter">
            <i className="fab fa-x-twitter"></i>
        </a>
        <a href="#" aria-label="Visit Instagram">
            <i className="fab fa-instagram"></i>
        </a>
        <a href="#" aria-label="Visit TikTok">
            <i className="fab fa-tiktok"></i>
        </a>
        <a href="#" aria-label="Visit GitHub">
            <i className="fab fa-github"></i>
        </a>
    </div>
                </div> 
                
                <div className="copyright" style={{textAlign:'left', fontWeight:'lighter', color:"#bbbbbb"}}><p> {new Date().getFullYear()} Alpha Centauri Inc &copy;. All rights reserved.</p>
                <p>Version: {process.env.REACT_APP_VERSION}</p> {/* Display the version here */} </div>
            </div>
            </div>   
        </div>
    </footer>
);
}