import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './components/Header/Header';
import TabButtons from './components/TabButtons';
import { TabButtonsInfo } from './data';
import StudyConcepts from './components/StudyConcepts';
import CodeCommandConcepts from './components/CodeCommandConcepts';
import PopularCourses from './components/Course_Card/PopularCourses';
import ThemeProvider from './components/Settings/Theme';
import Settings from './components/Settings/Setting';
//import { PageViewProvider } from './components/Course_Card/PageViewContext';
import './components/Settings/i18n'; // Correct path to i18n.js
import Language from './components/Settings/Language';
import UpdateNotification from './components/UpdateNotification/UpdateNotification';
import Utilities from './components/utilities/utilities';
import AdminAddCourse from './components/admin_add_course';
import PageViews from './components/pageviews';
import NavigationBar from './components/courses/ccna/ccna2/NavigationBar';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Alert } from '@mui/material';
import { Helmet } from 'react-helmet';
import Ccna from './components/courses/ccna/ccna';
import QuizMap from './components/Quizmap';
import {Box, Grid, Typography,useMediaQuery, useTheme, Container, Card} from '@mui/material';

function App() {

  <div>
      <Helmet>
        {/* Title for the Home Page */}
        <title>Alpha Centauri Inc. - Networking Resources, Cisco Commands, Calculators, and Certification Guides</title>

        {/* Description for SEO */}
        <meta
          name="description"
          content="Explore comprehensive resources for Cisco certifications, including CCNA, CCNP, device configuration guides, Cisco command references, and tools like IPv4/IPv6 calculators, and number system converters (binary, decimal, hexadecimal, octal)."
        />

        {/* Keywords for SEO */}
        <meta
          name="keywords"
          content="CCNA Exam Answers, CCNA Answers, Cisco commands, CCNA, CCNP, IPv4 calculator, IPv6 calculator, binary converter, decimal converter, hexadecimal converter, octal converter, networking tools, device configuration, packet tracer labs, Cisco certification"
        />

        {/* Open Graph meta tags for social sharing */}
        <meta property="og:title" content="Alpha Centauri Inc. - Networking Resources, Cisco Commands, Calculators, and Certification Guides" />
        <meta property="og:description" content="Access detailed guides for Cisco certifications, CCNA, and CCNP, including Cisco commands, device configurations, and IP calculators (IPv4, IPv6), plus binary, decimal, hexadecimal, and octal converters." />
        <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
        <meta property="og:url" content="https://alphacentauriinc.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card meta tags for Twitter sharing */}
        <meta name="twitter:title" content="Alpha Centauri Inc. - Networking Resources, Cisco Commands, Calculators, and Certification Guides" />
        <meta name="twitter:description" content="Learn Cisco commands, practice with IP calculators (IPv4, IPv6), and use converters for binary, decimal, hexadecimal, and octal. Enhance your networking skills at Alpha Centauri Inc." />
        <meta name="twitter:image" content="%PUBLIC_URL%/logo192.png" />
        <meta name="twitter:card" content="summary_large_image" />

        {/* Canonical link to prevent duplicate content issues */}
        {/* <link rel="canonical" href="" /> */}

        {/* Structured Data for Article/Tools */}
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": "Alpha Centauri Inc. - Networking & Cisco Resources",
            "description": "Explore Cisco commands, IP calculators, device configurations, and number system converters to enhance your networking skills and pass certifications.",
            "url": "https://alphacentauriinc.com",
            "logo": "%PUBLIC_URL%/logo192.png",
            "publisher": {
              "@type": "Organization",
              "name": "Alpha Centauri Inc."
            },
            "mainEntityOfPage": "https://alphacentauriinc.com"
          }
          `}
        </script>
      </Helmet>

      {/* Home Page content */}
      <h1>Welcome to Alpha Centauri Inc.</h1>
      <p>
        Alpha Centauri Inc. offers the best resources for Cisco certifications like **CCNA**, **CCNP**, and **CCIE**. Whether you're preparing for exams or need practical networking tools, we've got you covered.
      </p>

      <h2>Explore Our Key Features:</h2>
      <ul>
        <li><strong>Cisco Command Reference:</strong> Find the most comprehensive list of Cisco commands for routers, switches, firewalls, and more.</li>
        <li><strong>IP Calculators:</strong> Use our <a href="">IPv4 to IPv6 calculator</a> and <a href="">IPv6 calculator</a> to convert IP addresses and understand network structures.</li>
        <li><strong>Number System Converters:</strong> Convert between different number systems including <a href="">binary</a>, <a href="">decimal</a>, <a href="">hexadecimal</a>, and <a href="">octal</a>.</li>
        <li><strong>Device Configuration Guides:</strong> Detailed device configuration examples for routers, switches, and firewalls to help you set up and troubleshoot your network.</li>
        <li><strong>Packet Tracer Labs:</strong> Practical labs and Packet Tracer exercises for hands-on Cisco training.</li>
      </ul>

      <h2>Boost Your Networking Knowledge</h2>
      <p>
        Whether you’re a student preparing for your **CCNA** or a professional needing a quick reference for Cisco commands, our site offers everything you need to succeed.
      </p>
      <p>
        <strong>Get started with our Cisco tools, calculators, and guides today, and take your networking skills to the next level!</strong>
      </p>

      {/* Additional Home Page content */}
      {/* For example: links to other pages, site resources, or blog posts */}
    </div>

  const linksArray = ["Home","Courses", "Calculator"];

  useEffect(() => {
    const checkForMissedUpdate = () => {
      const updateNoticeTime = parseInt(localStorage.getItem('updateNoticeTime') || '0', 10);
      const waitTimeInMs = 2 * 60 * 1000; // 2 minutes for testing
      
      if (updateNoticeTime > 0 && Date.now() - updateNoticeTime > waitTimeInMs) {
        console.log('Missed update detected');
        if (navigator.onLine) {
          console.log('Online, updating now');
          // Simulate update
          localStorage.removeItem('updateNoticeTime');
          alert('Missed update applied!');
        } else {
          console.log('Offline, will check again soon');
          setTimeout(checkForMissedUpdate, 10 * 1000); // Check again in 10 seconds
        }
      }
    };

    checkForMissedUpdate();
  }, []);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // For xs, sm breakpoint
  const isLandscape = useMediaQuery('(orientation: landscape)'); // Detect landscape mode



  const [selectedTopic, setSelectedTopic] = useState('CCNA');
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const [showPopup, setShowPopup] = useState(false); // State to control the popup display
  const [showAlert, setShowAlert] = useState(false); // State to show the alert
  const navigate = useNavigate();

  function selectHandler(selectedButton) {
    setSelectedTopic(selectedButton);
  }

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Show popup if the device is in portrait mode
    if (window.innerHeight > window.innerWidth) {
      setShowPopup(true); // Show popup in portrait mode
    } else {
      setShowPopup(false); // Hide popup in landscape mode
    }

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleYes = () => {
    // Show the MUI Alert component when the user clicks "Yes"
    setShowAlert(true);  // Show info alert
    setShowPopup(false); // Close the popup
  };

  const handleNo = () => {
    setShowPopup(false); // Close the popup when the user clicks "No"
  };

  return (
    <ThemeProvider>
    {/* <PageViewProvider> */}
      {/* check back later for drawer */}
    <NavigationBar links={linksArray}  />

      {/* <NavigationBar/> */}
      {/* <Language /> */}
      {/* <Settings /> */}

      {/* {showAlert && (
      <Alert severity="info" onClose={() => setShowAlert(false)} style={{ position: 'fixed', top: 100, left: '50%', transform: 'translateX(-50%)', zIndex: 999 }}>
        Please go to settings and select landscape then rotate your device manually to landscape mode.
      </Alert>
    )} */}

          {/* Display the MUI Dialog (Popup) */}
          {/* <Dialog open={showPopup} onClose={handleNo}>
      <DialogTitle>Portrait Mode</DialogTitle>
      <DialogContent>
        <p>Your device is in portrait mode. To experience the best layout, we recommend switching to landscape mode.</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleYes} color="primary">
          Yes, Guide Me
        </Button>
        <Button onClick={handleNo} color="secondary">
          No, I’ll Continue
        </Button>
      </DialogActions>
    </Dialog> */}

<Container sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', padding: '16px', borderRadius: '8px' }}>
        <div>
          {/* Only render Grid and Card if the screen is small and in portrait mode */}
          {(isSmallScreen && !isLandscape) ? (
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: 'rgba(17, 14, 14, 0.6)',
                    '&:hover': { opacity: 0.8 },
                    margin: '10px 0', // Optional: add margin for spacing
                  }}
                  style={{ padding: '16px' }}
                >
                  <Header />
                  
                  <StudyConcepts />
                  <div style={{ marginTop: '20px' , marginBottom: '20px'}}></div>
                  <main>
                    <section id="tab-sections" >
                      <h2>Courses</h2>
                      <menu>
                        <TabButtons isSelected={selectedTopic === 'CCNA'} onSelect={() => selectHandler('CCNA')}>
                          CCNA
                        </TabButtons>
                      </menu>
                      <div id="tab-content">
                        <img src={TabButtonsInfo[selectedTopic].image} alt={TabButtonsInfo[selectedTopic].title} className="topic-tab-image" />
                        <h3>{TabButtonsInfo[selectedTopic].title}</h3>
                        <p style={{textWrap:"wrap"}}>{TabButtonsInfo[selectedTopic].description}</p>
                        <pre>
                          <code>{TabButtonsInfo[selectedTopic].code}</code>
                        </pre>
                        <div style={{ marginTop: '20px' , marginBottom: '20px'}}></div>
                        {TabButtonsInfo[selectedTopic].route && (
                          <button className="custom-route-button" onClick={() => navigate(TabButtonsInfo[selectedTopic].route)}>
                            Learn {TabButtonsInfo[selectedTopic].title} Now
                          </button>
                        )}
                      </div>
                    </section>
                  </main>
                </Card>
              </Grid>
            </Grid>
          ) : (
            // For larger screens or landscape mode, render the elements outside of Grid/Card
            <Box sx={{ padding: '16px' }}>
              <Header />
              <StudyConcepts />
              <main>
                <section id="tab-sections">
                  <h2>Courses</h2>
                  <menu>
                    <TabButtons isSelected={selectedTopic === 'CCNA'} onSelect={() => selectHandler('CCNA')}>
                      CCNA
                    </TabButtons>
                  </menu>
                  <div id="tab-content">
                    <img src={TabButtonsInfo[selectedTopic].image} alt={TabButtonsInfo[selectedTopic].title} className="topic-tab-image" />
                    <h3>{TabButtonsInfo[selectedTopic].title}</h3>
                    <p>{TabButtonsInfo[selectedTopic].description}</p>
                    <pre>
                      <code>{TabButtonsInfo[selectedTopic].code}</code>
                    </pre>
                    <div style={{ marginTop: '20px' , marginBottom: '20px'}}></div>
                    {TabButtonsInfo[selectedTopic].route && (
                      <button className="custom-route-button" onClick={() => navigate(TabButtonsInfo[selectedTopic].route)}>
                        Learn {TabButtonsInfo[selectedTopic].title} Now
                      </button>
                    )}
                  </div>
                </section>
              </main>
            </Box>
          )}
        </div>
        <Ccna />
      </Container>
          </ThemeProvider>
  );
}export default App;

if (module.hot) {
  module.hot.accept();
  module.hot.addStatusHandler(status => {
    if (status === 'apply') {
      window.postMessage('updateAvailable', '*');
    }
  });
}