import React, { useState, useEffect, useCallback } from 'react';
import CCNA1_Quiz_Bank from '../../../../Settings/locales/en/ccna3_translation.json';
import Question from './Question';
import Result from './Result';
import './Quiz.css';
import ScrollToTop from '../../../../../ScrolltopPage';
import ErrorBoundary from '../../../../ErrorBoundary';  // To handle errors
import { 
    Container, 
    Typography, 
    Button, 
    Select, 
    MenuItem,  
    Box, 
    Grid, 
    Card, 
    CardContent, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions,
} from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

// Import the NavigationBar component
import NavigationBar from '../../ccna2/NavigationBar';
const linksArray = ["Home", "Courses", "Calculator"];


// Fisher-Yates Shuffle Implementation
const fisherYatesShuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1)); // Get a random index from 0 to i
        [array[i], array[j]] = [array[j], array[i]]; // Swap the elements
    }
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[200],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: 'green',
    },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
    },
}));

const formatChapterName = (chapterName) => {
    // Adds a space between "chapter" and the number
    return chapterName.replace(/(\d+)/, ' $1');
};

const ChapterQuiz = () => {
    const [chapter, setChapter] = useState("");
    const [questions, setQuestions] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [timer, setTimer] = useState(4500); // Timer in seconds
    const [showResult, setShowResult] = useState(false);
    const [filterOption, setFilterOption] = useState("all");
    const [showSubmitDialog, setShowSubmitDialog] = useState(false);
    const [startTime, setStartTime] = useState(null); // Store the start time
    const [countdown, setCountdown] = useState(null);

    const questionsPerPage = 10;

    const initializeQuiz = useCallback((selectedChapter) => {
        console.log("Selected Chapter:", selectedChapter);
        
        // Check if the chapter exists in the question bank
        if (!CCNA1_Quiz_Bank || !CCNA1_Quiz_Bank[selectedChapter]) {
            console.error(`No questions found for chapter: ${selectedChapter}`);
            return;
        }

        const chapterQuestions = CCNA1_Quiz_Bank[selectedChapter];
        
        if (!Array.isArray(chapterQuestions)) {
            console.error(`Questions for ${selectedChapter} is not an array`);
            return;
        }

        // Shuffle questions randomly
        // const shuffledQuestions = [...chapterQuestions].sort(() => Math.random() - 0.5);
        
        // shuffledQuestions.forEach((question) => {
        //     if (question.type === "multiple-choice" && Array.isArray(question.answers)) {
        //         question.answers = [...question.answers].sort(() => Math.random() - 0.5);
        //         question.points = question.answers.filter((a) => a.isCorrect).length;
        //     } else if (question.type === "matching") {
        //         question.points = question.items.length;
        //         question.options = [...question.options].sort(() => Math.random() - 0.5);
        //     }
        // });

        fisherYatesShuffle(chapterQuestions); // Changed to Fisher-Yates shuffle

        chapterQuestions.forEach((question) => {
            if (question.type === "multiple-choice" && Array.isArray(question.answers)) {
                fisherYatesShuffle(question.answers); // Shuffle the answers for multiple-choice questions
                question.points = question.answers.filter((a) => a.isCorrect).length;
            } else if (question.type === "matching") {
                question.points = question.items.length;
                fisherYatesShuffle(question.options); // Shuffle options for matching questions
            }
        });

        // Update state with shuffled questions
        setQuestions(chapterQuestions);
        setChapter(selectedChapter);

        setStartTime(Date.now());  // Store the current time as the start time
        // Reset timer to initial value to 4500 seconds or (30 minutes)
        setTimer(4500);
    }, []);
    //     if (countdown) clearInterval(countdown);

    //     // Start countdown timer
    //     const newCountdown = setInterval(() => {
    //         setTimer((prevTimer) => prevTimer - 1);
    //     }, 1000);
    //     setCountdown(newCountdown);
    // }, [countdown]);

    const handleSubmit = useCallback(() => {
        setShowSubmitDialog(false);
        setShowResult(true);
        if (countdown) clearInterval(countdown);
    }, [countdown]);

    useEffect(() => {
        if (startTime){

            const interval = setInterval(() => {
                // Calculate the elapsed time and update the timer
                const elapsed = Math.floor((Date.now() - startTime) / 1000); // elapsed time in seconds
                const newTimer = 4500 - elapsed;
                if (newTimer <= 0) {
                    clearInterval(interval);  // Stop the timer once it reaches 0
                    handleSubmit();
                } else {
                    setTimer(newTimer);  // Update the timer
                }
            }, 1000);
            // setCountdown(interval);

            return () => clearInterval(interval); // Cleanup on unmount or if timer is stopped
        }
    }, [startTime, handleSubmit]);
        

    // useEffect(() => {
    //     return () => {
    //         if (countdown) clearInterval(countdown);
    //     };
    // }, [countdown]);

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;
        return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleAnswerSelect = (questionId, answer) => {
        setSelectedAnswers((prevAnswers) => {
            const question = questions.find((q) => q.id === questionId);
            if (question.type === "multiple-choice") {
                const currentSelection = prevAnswers[questionId] || [];
                const correctAnswersCount = question.answers.filter((a) => a.isCorrect).length;

                if (currentSelection.includes(answer)) {
                    return {
                        ...prevAnswers,
                        [questionId]: currentSelection.filter((idx) => idx !== answer),
                    };
                } else {
                    if (currentSelection.length < correctAnswersCount) {
                        return {
                            ...prevAnswers,
                            [questionId]: [...currentSelection, answer],
                        };
                    } else {
                        return {
                            ...prevAnswers,
                            [questionId]: [answer],
                        };
                    }
                }
            } else if (question.type === "matching") {
                return {
                    ...prevAnswers,
                    [questionId]: answer, 
                };
            }
            return prevAnswers;
        });
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(questions.length / questionsPerPage) - 1) {
            setCurrentPage(currentPage + 1);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const handleFilterChange = (event) => {
        setFilterOption(event.target.value);
    };

    const handleOpenSubmitDialog = () => {
        setShowSubmitDialog(true);
    };

    const handleCloseSubmitDialog = () => {
        setShowSubmitDialog(false);
    };

    const questionsAnswered = Object.keys(selectedAnswers).length;

    const filteredQuestions = questions ? questions.filter((q) => {
        if (q.type === "multiple-choice") {
            const correctAnswersCount = q.answers.filter((a) => a.isCorrect).length;
            const isAnswered = selectedAnswers[q.id] && selectedAnswers[q.id].length > 0;
            const isUnanswered = !isAnswered || selectedAnswers[q.id].length < correctAnswersCount;

            if (filterOption === "answered") return isAnswered;
            if (filterOption === "unanswered") return isUnanswered;
        } else if (q.type === "matching") {
            const isAnswered = selectedAnswers[q.id] && Object.keys(selectedAnswers[q.id]).length === q.items.length;
            const isUnanswered = !isAnswered;

            if (filterOption === "answered") return isAnswered;
            if (filterOption === "unanswered") return isUnanswered;
        }
        return true;
    }) : [];

    const displayedQuestions = filterOption === "answered"
        ? filteredQuestions
        : filteredQuestions.slice(currentPage * questionsPerPage, (currentPage + 1) * questionsPerPage);

    const timeRemainingPercent = (timer / 180) * 100;

    const chaptersWithQuestions = Object.keys(CCNA1_Quiz_Bank).filter(chapter => {
        return Array.isArray(CCNA1_Quiz_Bank[chapter]) && CCNA1_Quiz_Bank[chapter].length > 0;
    });

    return (
        <ErrorBoundary>
            {showResult ? (
                <>
                    <ScrollToTop />
                          {/* Add margin to the Utilities component */}
                     <div style={{ marginTop: '60px' , marginBottom: '40px'}}></div>
                    <Result questions={questions} selectedAnswers={selectedAnswers} />
                    {/* Render NavigationBar only when the user reaches the result section */}
                    <NavigationBar  links={linksArray}/>
                </>
            ) : chapter ? (
                <div className='quiz-container'>
                    <div className="sticky-top">
                        <h2 className="chapter-name">{formatChapterName(chapter)}</h2>
                        <div className="progress-info">
                            <Box sx={{ width: '100%', marginBottom: 2 }}>
                                <BorderLinearProgress  
                                    variant="determinate" 
                                    value={(questionsAnswered / questions.length) * 100} 
                                />
                            </Box>
                            <div className="answered-info">
                                <Typography variant="body2">
                                    {questionsAnswered} / {questions.length} Questions
                                </Typography>
                            </div>
                        </div>
                        <div className="timer-progress">
                            <Box sx={{ width: '100%', marginBottom: 2 }}>
                                <LinearProgress 
                                    variant="determinate" 
                                    value={timeRemainingPercent} 
                                    color={timeRemainingPercent > 50 ? "success" : timeRemainingPercent > 25 ? "warning" : "error"} 
                                />
                            </Box>
                            <div className="timer">
                                <Typography variant="body2">{formatTime(timer)}</Typography>
                            </div>
                        </div>
                    </div>

                    <div className="question-list">
                        {displayedQuestions.map((question, index) => (
                            <Question
                                key={question.id}
                                question={question}
                                onSelectAnswer={handleAnswerSelect}
                                selectedAnswers={selectedAnswers[question.id] || []}
                                questionNumber={currentPage * questionsPerPage + index + 1}
                            />
                        ))}
                    </div>

                    <div className="sticky-bottom quiz-navigation">
                        <Button onClick={handlePrevPage} disabled={currentPage === 0 || filterOption === "answered"}>Previous</Button>
                        <Select onChange={handleFilterChange} value={filterOption}
                                        sx={{
                                            color: '#1976d2',
                                            '& .MuiSvgIcon-root': {
                                              color: '#1976d2',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#1976d2',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#1976d2',
                                            },
                            
                                          }}
                                          MenuProps={{
                                            PaperProps: {
                                              sx: {
                                                background: 'rgba(17, 14, 14, 0.75)',
                                                boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
                                                backdropFilter: 'blur(2px)',
                                                WebkitBackdropFilter: 'blur(2px)',
                                                borderRadius: '10px',
                                                border: '1px solid rgba(255, 255, 255, 0.18)',
                                                '& .MuiMenuItem-root': {
                                                  color: '#1976d2',
                                                },
                                              },
                                            },
                                          }}
                        >
                            <MenuItem value="all">Show All Questions</MenuItem>
                            <MenuItem value="answered">Show Answered Questions</MenuItem>
                            <MenuItem value="unanswered">Show Unanswered Questions</MenuItem>
                        </Select>
                        <Button onClick={handleNextPage} disabled={currentPage >= Math.ceil(filteredQuestions.length / questionsPerPage) - 1 || filterOption === "answered"}>Next</Button>
                        <Button onClick={handleOpenSubmitDialog}>Submit</Button>
                    </div>

                    {showSubmitDialog && (
                        <div className="submit-dialog">
                            <div className="dialog-content">
                                <p>Are you sure you want to submit now?</p>
                                <button onClick={handleSubmit}>Yes</button>
                                <button onClick={handleCloseSubmitDialog}>No</button>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="chapter-selection">
            {/* Add margin to the Utilities component */}
        <div style={{ marginTop: '60px' , marginBottom: '60px'}}></div>

                    <h2>Select a Chapter to Start the Quiz</h2>
            {/* Add margin to the Utilities component */}
        <div style={{ marginTop: '20px' , marginBottom: '20px'}}></div>

                    <div className="chapter-card-container">

                        {chaptersWithQuestions.map((chapterName) => (
                            <div
                                key={chapterName}
                                className="chapter-card"
                                onClick={() => initializeQuiz(chapterName)}
                            >
                                <h3 className="chapter-card-title">{formatChapterName(chapterName)}</h3>
                            </div>
                        ))}
                    </div>
                            {/* Add margin to the Utilities component */}
        <div style={{ marginTop: '60px' , marginBottom: '60px'}}></div>

                </div>
            )}
        </ErrorBoundary>
    );
};

export default ChapterQuiz;
