// src/components/courses/ccna/Calculator/hexadecimal_converter.js 

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Container, Button, Dialog, DialogContent, Alert, AlertTitle, Typography, Grid } from '@mui/material';
import NavigationBar from '../ccna2/NavigationBar'; 
import Utilities from '../../../utilities/utilities';
import { Helmet } from 'react-helmet';

// Convert hexadecimal to binary
const hexadecimalToBinary = (hex) => {
  return parseInt(hex, 16).toString(2);
};

// Convert hexadecimal to decimal
const hexadecimalToDecimal = (hex) => {
  return parseInt(hex, 16);
};

// Convert hexadecimal to octal
const hexadecimalToOctal = (hex) => {
  return parseInt(hex, 16).toString(8);
};

// Convert hexadecimal to IPv4 dotted decimal
const hexadecimalToIPv4 = (hex) => {
  const decimal = hexadecimalToDecimal(hex);
  const binary = decimal.toString(2).padStart(32, '0');
  const octets = [];
  for (let i = 0; i < 32; i += 8) {
    octets.push(parseInt(binary.slice(i, i + 8), 2));
  }
  return octets.join('.');
};

// Convert hexadecimal to IPv6 (assuming the first 128 bits)
const hexadecimalToIPv6 = (hex) => {
  const decimal = hexadecimalToDecimal(hex);
  const binary = decimal.toString(2).padStart(128, '0');
  const parts = [];
  for (let i = 0; i < 128; i += 16) {
    const part = binary.slice(i, i + 16);
    parts.push(parseInt(part, 2).toString(16));
  }
  return parts.join(':');
};

// Convert hexadecimal to dotted decimal subnet mask
const hexadecimalToDottedDecimalSubnetMask = (hex) => {
  const decimal = hexadecimalToDecimal(hex);
  const binary = decimal.toString(2).padStart(32, '0');
  const octets = [];
  for (let i = 0; i < 32; i += 8) {
    octets.push(parseInt(binary.slice(i, i + 8), 2));
  }
  return octets.join('.');
};

// Validate hexadecimal input
const isValidHexadecimal = (input) => {
  return /^[0-9A-Fa-f]*$/.test(input);
};

const HexadecimalConverter = () => {
  const [hexInput, setHexInput] = useState('');
  const [conversionResults, setConversionResults] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleConvert = () => {
    if (isValidHexadecimal(hexInput)) {
      const binary = hexadecimalToBinary(hexInput);
      const decimal = hexadecimalToDecimal(hexInput);
      const octal = hexadecimalToOctal(hexInput);
      const ipv4 = hexadecimalToIPv4(hexInput);
      const ipv6 = hexadecimalToIPv6(hexInput);
      const subnetMaskDottedDecimal = hexadecimalToDottedDecimalSubnetMask(hexInput);

      setConversionResults({
        binary,
        decimal,
        octal,
        ipv4,
        ipv6,
        subnetMaskDottedDecimal,
      });
      setErrorMessage('');
    } else {
      setErrorMessage('Hexadecimal can only be digits 0-9 and letters A-F.');
      setConversionResults(null);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent page reload
      handleConvert();
    }
  };

  <div>

    {/* Page content */}
    <h1>Hexadecimal Converter</h1>
    <p>
      Use our Hexadecimal Converter to easily convert hexadecimal numbers into binary, decimal, octal, and more. Learn about number system conversions and see examples of how to convert between hexadecimal and other number bases. Whether you are a student, developer, or professional, this tool will help you with quick and accurate conversions.
    </p>

    {/* Add the content for the Hexadecimal Converter functionality here */}
    {/* (e.g., hex number input form, conversion options, results display, etc.) */}
  </div>

  return (
    
    <div>
      
          <Helmet>
      <title>Hexadecimal Converter - Convert Hexadecimal to Binary, Decimal, Octal & More | Alpha Centauri Inc.</title>
      <meta
        name="description"
        content="Convert hexadecimal numbers to binary, decimal, octal, and other formats with our Hexadecimal Converter tool. Learn about number system conversions and get detailed explanations with examples."
      />
      <meta
        name="keywords"
        content="hexadecimal converter, hex to binary, hex to decimal, hex to octal, number base conversion, hex calculator, binary converter, decimal calculator, number system conversion"
      />
      <meta
        name="robots"
        content="index, follow"
      />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content="Hexadecimal Converter - Convert Hexadecimal to Binary, Decimal, Octal & More" />
      <meta property="og:description" content="Convert hexadecimal numbers to binary, decimal, octal, and more. Learn number system conversions and get results instantly with detailed examples." />
      <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
      <meta property="og:url" content="https://alphacentauriinc.com/ccna/calculator/hexadecimal_converter" />
      <meta property="og:type" content="website" />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:title" content="Hexadecimal Converter - Convert Hexadecimal to Binary, Decimal, Octal & More" />
      <meta name="twitter:description" content="Convert hexadecimal numbers to binary, decimal, octal, and more. Instant conversion with detailed results and examples." />
      <meta name="twitter:image" content="%PUBLIC_URL%/logo192.png" />
      <meta name="twitter:card" content="summary_large_image" />

      {/* Canonical Link */}
      <link rel="canonical" href="/ccna/calculator/hexadecimal_converter" />

      {/* Structured Data for Article/Tools */}
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "WebApplication",
          "name": "Hexadecimal Converter",
          "description": "Convert hexadecimal numbers to binary, decimal, octal, and other formats. Get quick conversions for various number systems.",
          "url": "https://alphacentauriinc.com/ccna/calculator/hexadecimal_converter",
          "logo": "%PUBLIC_URL%/logo192.png",
          "publisher": {
            "@type": "Organization",
            "name": "Alpha Centauri Inc."
          },
          "mainEntityOfPage": "https://alphacentauriinc.com/ccna/calculator/hexadecimal_converter"
        }
        `}
      </script>

    </Helmet>

      <NavigationBar 
        links={['Home', 'Courses','Calculator']} 
        chapters={[]} 
        onSearch={() => {}} 
        searchTerm="" 
        clearSearch={() => {}} 
        hasChapters={false} 
      />   

      <Container 
        sx={{ 
          background: 'rgba(17, 14, 14, 0.6)', 
          padding: 2, 
          borderRadius: 1,
        }}
      >
        <Box sx={{ paddingTop: 4 }}>
          <Typography variant="h4" color="#1976d2" gutterBottom>
            Hexadecimal Converter
          </Typography>
          <p>
      Use our Hexadecimal Converter to easily convert hexadecimal numbers into binary, decimal, octal, and more. Learn about number system conversions and see examples of how to convert between hexadecimal and other number bases. Whether you are a student, developer, or professional, this tool will help you with quick and accurate conversions.
    </p>
    <div style={
          {marginBottom:"20px"}
        }></div>

          {errorMessage && (
            <Dialog open={Boolean(errorMessage)} onClose={() => setErrorMessage('')}>
              <DialogContent>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {errorMessage}
                </Alert>
              </DialogContent>
            </Dialog>
          )}

          <Box component="form" sx={{ m: 1 }} noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <style>
        {`
            /* For Firefox */
            * {
                scrollbar-width: thin; /* Options: auto, thin */
                scrollbar-color: #1976d2 transparent; /* thumb color and track color */
            }

            /* For WebKit browsers (Chrome, Safari) */
            ::-webkit-scrollbar {
                width: 8px; /* Width of the scrollbar */
            }

            ::-webkit-scrollbar-track {
                background: transparent; /* Background of the scrollbar track */
            }

            ::-webkit-scrollbar-thumb {
                background: #1976d2; /* Color of the scrollbar thumb */
                border-radius: 10px; /* Optional: round the corners of the thumb */
            }

            ::-webkit-scrollbar-thumb:hover {
                background: #155a8a; /* Darker shade on hover */
            }
        `}
    </style>
                <TextField
                  label="Hexadecimal Input"
                  value={hexInput}
                  onChange={(e) => setHexInput(e.target.value)}
                  onKeyPress={handleKeyPress} // Trigger conversion on Enter key
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    sx: { color: '#1976d2', '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                  },
                    readOnly: false,
                  }}
                  InputLabelProps={{
                    sx: { color: '#1976d2' },
                  }}
                />
              </Grid>
            </Grid>

            <Button variant="contained" color="primary" onClick={handleConvert} sx={{ marginTop: 2 }}>
              Convert
            </Button>
          </Box>

          {conversionResults && (
            <Box sx={{ marginTop: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Binary"
                    value={conversionResults.binary}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Decimal"
                    value={conversionResults.decimal}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Octal"
                    value={conversionResults.octal}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="IPv4 Dotted Decimal"
                    value={conversionResults.ipv4}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="IPv6"
                    value={conversionResults.ipv6}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Subnet Mask (Dotted Decimal)"
                    value={conversionResults.subnetMaskDottedDecimal}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Container>
      <Utilities />
    </div>
  );
};

export default HexadecimalConverter;