import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import i18n from './components/Settings/i18n';
import Routepath from './components/route-paths';
import { I18nextProvider } from 'react-i18next';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const appName = "Alpha Centauri"; // App Name is hardcoded
const appVersion = process.env.REACT_APP_VERSION || "1.0.0"; // Get version from .env or package.json

function Root() {
  const [updateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    // Remove the service worker on the new release
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (registration) {
          // Unregister the service worker to stop it from controlling the app
          registration.unregister().then((success) => {
            if (success) {
              console.log('Service worker unregistered successfully');
            } else {
              console.log('Failed to unregister service worker');
            }
          });

          // Clear any caches if needed
          caches.keys().then((cacheNames) => {
            cacheNames.forEach((cacheName) => {
              caches.delete(cacheName);
            });
          });
        }
      });

      // Listen for service worker updates
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data === 'UPDATE_FOUND') {
          setUpdateAvailable(true); // Show notification when update is found
        }
      });

      // Send the app name and version to the service worker if still active
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: 'SET_APP_INFO',
          appName,
          appVersion,
        });
      }
    }
  }, []);

  return (
    <div>
      {/* Show the update notification with app name and version */}
      {updateAvailable && (
        <div className="update-banner">
          <strong>{appName}</strong> version {appVersion} is now available. Please refresh to get the latest updates.
        </div>
      )}

      {/* Main app component */}
      <I18nextProvider i18n={i18n}>
        <Routepath />
      </I18nextProvider>
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
  <I18nextProvider i18n={i18n}>
    <Routepath />
  </I18nextProvider>
//</React.StrictMode>
);
serviceWorkerRegistration.unregister();
// Unregister the service worker to disable it in the new release
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.getRegistration().then((registration) => {
//     if (registration) {
//       registration.unregister().then((success) => {
//         if (success) {
//           console.log('Service worker unregistered successfully');
//         } else {
//           console.log('Failed to unregister service worker');
//         }
//       });

//       // Clear any cached data (optional)
//       caches.keys().then((cacheNames) => {
//         cacheNames.forEach((cacheName) => {
//           caches.delete(cacheName);
//         });
//       });
//     }
//   });
// }
