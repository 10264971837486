// path/to/your/file
import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; // Import Grid from Material-UI
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import NavigationBar from '../ccna2/NavigationBar';
import Jsx_ui from '../../../../assets/jsx-ui.png';
import Config from '../../../../assets/config.png';
import IPV4 from '../../../../assets/ipv41.webp'
import IPV6 from'../../../../assets/ipv6.webp';
import Binary from '../../../../assets/binary.webp';
import DEC from '../../../../assets/dec.webp';
import HEX from '../../../../assets/hex.webp';
import OCT from '../../../../assets/oct.webp';


const links = ['Home','Courses', 'Calculator'];
const chapters = []; // or some array of chapter objects if applicable
const onSearch = (searchTerm) => {
  // Handle search logic
};

const Calculators = ({ showNav = true }) => {
  return (
    <div>
      {showNav && (
        <NavigationBar 
          links={links} 
          chapters={chapters} // Pass an empty array if no chapters
          onSearch={onSearch} 
          searchTerm="" 
          clearSearch={() => {}} 
          hasChapters={chapters.length > 0} // Optional: pass this prop if needed
        />  
      )}
      <Container sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', padding: '16px', borderRadius: '8px' }}>
      <Typography variant="h4" color="#1976d2" gutterBottom>
            Calculators
          </Typography>
        <Grid container spacing={2}>

          {/* Card for IPv4 Calculator */}
          <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 } }}>
              <Link to="/ccna/calculator/ipv4_calculator" style={{ textDecoration: 'none', color: 'inherit' }}>
                <AspectRatio sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', '& .MuiAspectRatio-content': { backgroundColor: 'rgba(17, 14, 14, 0.6)' } }}>
                  <img src={IPV4} alt="IPv4 Calculator" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </AspectRatio>
                <div>
                  <Typography level="title-md" sx={{ color: '#1976d2' }}>IPv4 Calculator</Typography>
                  <Typography level="body-sm" sx={{ color: '#1976d2' }}>Description of the IPv4 Calculator.</Typography>
                </div>
              </Link>
            </Card>
          </Grid>

          {/* Card for IPv6 Calculator */}
          <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 } }}>
              <Link to="/ccna/calculator/ipv6_calculator" style={{ textDecoration: 'none', color: 'inherit' }}>
                <AspectRatio sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', '& .MuiAspectRatio-content': { backgroundColor: 'rgba(17, 14, 14, 0.6)' } }}>
                  <img src={IPV6} alt="IPv6 Calculator" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </AspectRatio>
                <div>
                  <Typography level="title-md" sx={{ color: '#1976d2' }}>IPv6 Calculator</Typography>
                  <Typography level="body-sm" sx={{ color: '#1976d2' }}>Description of the IPv6 Calculator.</Typography>
                </div>
              </Link>
            </Card>
          </Grid>

          {/* Card for Binary Converter */}
          <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 } }}>
              <Link to="/ccna/calculator/binary_converter" style={{ textDecoration: 'none', color: 'inherit' }}>
              <AspectRatio sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', '& .MuiAspectRatio-content': { backgroundColor: 'rgba(17, 14, 14, 0.6)' } }}>
              <img src={Binary} alt="Binary Converter" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </AspectRatio>
                <div>
                  <Typography level="title-md" sx={{ color: '#1976d2' }}>Binary Converter</Typography>
                  <Typography level="body-sm" sx={{ color: '#1976d2' }}>Description of the Binary Converter.</Typography>
                </div>
              </Link>
            </Card>
          </Grid>

          {/* Card for Hexadecimal Converter */}
          <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 } }}>
              <Link to="/ccna/calculator/hexadecimal_converter" style={{ textDecoration: 'none', color: 'inherit' }}>
              <AspectRatio sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', '& .MuiAspectRatio-content': { backgroundColor: 'rgba(17, 14, 14, 0.6)' } }}>
              <img src={HEX} alt="Hexadecimal Converter" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </AspectRatio>
                <div>
                  <Typography level="title-md" sx={{ color: '#1976d2' }}>Hexadecimal Converter</Typography>
                  <Typography level="body-sm" sx={{ color: '#1976d2' }}>Description of the Hexadecimal Converter.</Typography>
                </div>
              </Link>
            </Card>
          </Grid>

          {/* Card for Octal Converter */}
          <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 } }}>
              <Link to="/ccna/calculator/octal_converter" style={{ textDecoration: 'none', color: 'inherit' }}>
              <AspectRatio sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', '& .MuiAspectRatio-content': { backgroundColor: 'rgba(17, 14, 14, 0.6)' } }}>
              <img src={OCT} alt="Octal Converter" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </AspectRatio>
                <div>
                  <Typography level="title-md" sx={{ color: '#1976d2' }}>Octal Converter</Typography>
                  <Typography level="body-sm" sx={{ color: '#1976d2' }}>Description of the Octal Converter.</Typography>
                </div>
              </Link>
            </Card>
          </Grid>

          {/* Card for Decimal Converter */}
          <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" sx={{ borderColor: '#1976d2', backgroundColor: 'rgba(17, 14, 14, 0.6)', '&:hover': { opacity: 0.8 } }}>
              <Link to="/ccna/calculator/decimal_converter" style={{ textDecoration: 'none', color: 'inherit' }}>
              <AspectRatio sx={{ backgroundColor: 'rgba(17, 14, 14, 0.6)', '& .MuiAspectRatio-content': { backgroundColor: 'rgba(17, 14, 14, 0.6)' } }}>
              <img src={DEC} alt="Decimal Converter" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </AspectRatio>
                <div>
                  <Typography level="title-md" sx={{ color: '#1976d2' }}>Decimal Converter</Typography>
                  <Typography level="body-sm" sx={{ color: '#1976d2' }}>Description of the Decimal Converter.</Typography>
                </div>
              </Link>
            </Card>
          </Grid>
        </Grid>
      </Container>
              {/* Add margin to the Utilities component */}
              <div style={{ marginTop: '40px' , marginBottom: '40px'}}></div>

    </div>
  );
};

export default Calculators;