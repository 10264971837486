// src/components/courses/ccna/Calculator/binary_converter.js

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Container, Button, Dialog, DialogContent, Alert, AlertTitle, Typography, Grid } from '@mui/material';
import NavigationBar from '../ccna2/NavigationBar'; // Adjust path as needed
import Utilities from '../../../utilities/utilities';
import { Helmet } from 'react-helmet';

// Convert binary to decimal (supports fractional part)
const binaryToDecimal = (binary) => {
  const parts = binary.split('.'); // Split into whole and fractional parts
  const wholePart = parts[0] || '0';
  const fractionalPart = parts[1] || '';

  // Step 1: Convert the whole part
  let decimal = 0;
  for (let i = 0; i < wholePart.length; i++) {
    decimal += parseInt(wholePart[wholePart.length - 1 - i], 2) * Math.pow(2, i);
  }

  // Step 2: Convert the fractional part
  let fractionalDecimal = 0;
  for (let i = 0; i < fractionalPart.length; i++) {
    fractionalDecimal += parseInt(fractionalPart[i], 2) * Math.pow(2, -(i + 1));
  }

  // Combine both parts
  return decimal + fractionalDecimal;
};

// Convert binary to octal (supports fractional part)
const binaryToOctal = (binary) => {
  const decimal = binaryToDecimal(binary);
  return decimal.toString(8);
};

// Convert binary to hexadecimal (supports fractional part)
const binaryToHexadecimal = (binary) => {
  const decimal = binaryToDecimal(binary);
  return decimal.toString(16).toUpperCase();
};

// Convert binary to IPv4 (no fractional part)
const binaryToIPv4 = (binary) => {
  const wholeBinary = binary.split('.')[0].padStart(32, '0'); // Ensure 32 bits for IPv4
  const octets = [];
  for (let i = 0; i < 32; i += 8) {
    octets.push(parseInt(wholeBinary.slice(i, i + 8), 2));
  }
  return octets.join('.');
};

// Convert binary to IPv6 (no fractional part)
const binaryToIPv6 = (binary) => {
  const wholeBinary = binary.split('.')[0].padStart(128, '0'); // Ensure 128 bits for IPv6
  const parts = [];
  for (let i = 0; i < 128; i += 16) {
    const part = wholeBinary.slice(i, i + 16);
    parts.push(parseInt(part, 2).toString(16));
  }
  return parts.join(':');
};

// Convert binary to subnet mask (no fractional part)
const binaryToDottedDecimalSubnetMask = (binary) => {
  const wholeBinary = binary.split('.')[0].padEnd(32, '0'); // Ensure 32 bits for subnet mask
  const octets = [];
  for (let i = 0; i < 32; i += 8) {
    octets.push(parseInt(wholeBinary.slice(i, i + 8), 2));
  }
  return octets.join('.');
};

// Validate binary input (allow dotted binary)
const isValidBinary = (input) => {
  return /^[01]+(\.[01]+)*$/.test(input); // Allow binary digits and periods separating groups
};

const BinaryConverter = () => {
  const [binaryInput, setBinaryInput] = useState('');
  const [conversionResults, setConversionResults] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleConvert = () => {
    const normalizedBinary = binaryInput.replace(/\./g, ''); // Remove periods for processing

    if (isValidBinary(binaryInput) && normalizedBinary.length > 0) {
      const decimal = binaryToDecimal(binaryInput); // Convert binary to decimal
      const octal = binaryToOctal(binaryInput);
      const hexadecimal = binaryToHexadecimal(binaryInput);
      const ipv4 = binaryToIPv4(binaryInput.padStart(32, '0')); // Ensure 32 bits for IPv4
      const ipv6 = binaryToIPv6(binaryInput.padStart(128, '0')); // Ensure 128 bits for IPv6
      const subnetMaskBinary = normalizedBinary.padEnd(32, '0'); // Ensure 32 bits for subnet mask
      const subnetMaskDottedDecimal = binaryToDottedDecimalSubnetMask(subnetMaskBinary);

      setConversionResults({
        decimal: decimal.toFixed(6), // Fix decimal to 6 places for floating point precision
        octal,
        hexadecimal,
        ipv4,
        ipv6,
        subnetMask: subnetMaskBinary,
        subnetMaskDottedDecimal,
      });
      setErrorMessage('');
    } else {
      setErrorMessage('Binary can only be ones, zeros, and periods.');
      setConversionResults(null);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent page reload
      handleConvert();
    }
  };

  <div>


  {/* Page content */}
  <h1>Binary Converter</h1>

  {/* Add the content for the Binary Converter functionality here */}
  {/* (e.g., binary number input form, conversion options, results display, etc.) */}
</div>

  return (
    <div>
        <Helmet>
    <title>Binary Converter - Convert Binary to Decimal, Hexadecimal, Octal & More | Alpha Centauri Inc.</title>
    <meta
      name="description"
      content="Convert binary numbers to decimal, hexadecimal, octal, and other formats with our Binary Converter tool. Learn about number system conversions and get detailed results with examples."
    />
    <meta
      name="keywords"
      content="binary converter, bin to decimal, bin to hexadecimal, bin to octal, number system conversion, binary calculator, decimal calculator, hexadecimal calculator, binary to other bases"
    />
    <meta
      name="robots"
      content="index, follow"
    />

    {/* Open Graph Meta Tags */}
    <meta property="og:title" content="Binary Converter - Convert Binary to Decimal, Hexadecimal, Octal & More" />
    <meta property="og:description" content="Convert binary numbers to decimal, hexadecimal, octal, and more. Get fast conversions with explanations and examples of how to convert binary to other bases." />
    <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
    <meta property="og:url" content="https://alphacentauriinc.com/ccna/calculator/binary_converter" />
    <meta property="og:type" content="website" />

    {/* Twitter Card Meta Tags */}
    <meta name="twitter:title" content="Binary Converter - Convert Binary to Decimal, Hexadecimal, Octal & More" />
    <meta name="twitter:description" content="Convert binary numbers to decimal, hexadecimal, octal, and other formats. Instant conversion with detailed results and examples." />
    <meta name="twitter:image" content="%PUBLIC_URL%/logo192.png" />
    <meta name="twitter:card" content="summary_large_image" />

    {/* Canonical Link */}
    <link rel="canonical" href="/ccna/calculator/binary_converter" />

    {/* Structured Data for Article/Tools */}
    <script type="application/ld+json">
      {`
      {
        "@context": "https://schema.org",
        "@type": "WebApplication",
        "name": "Binary Converter",
        "description": "Convert binary numbers to decimal, hexadecimal, octal, and other formats. Get quick conversions and see examples of binary to decimal, hexadecimal, and octal conversions.",
        "url": "https://alphacentauriinc.com/ccna/calculator/binary_converter",
        "logo": "%PUBLIC_URL%/logo192.png",
        "publisher": {
          "@type": "Organization",
          "name": "Alpha Centauri Inc."
        },
        "mainEntityOfPage": "https://alphacentauriinc.com/ccna/calculator/binary_converter"
      }
        `}
    </script>

  </Helmet>
      <NavigationBar 
        links={['Home', 'Courses','Calculator']} 
        chapters={[]} 
        onSearch={() => {}} 
        searchTerm="" 
        clearSearch={() => {}} 
        hasChapters={false} 
      />   

      <Container 
        sx={{ 
          background: 'rgba(17, 14, 14, 0.6)', 
          padding: 2, 
          borderRadius: 1,
        }}
      >
        <Box sx={{ paddingTop: 4 }}>
          <Typography variant="h4" color="#1976d2" gutterBottom>
            Binary Converter
          </Typography>
          <p>
    Use our Binary Converter to convert binary numbers to decimal, hexadecimal, octal, and other number formats. This tool allows you to quickly and easily convert between binary and other number systems. Whether you're a programmer, student, or IT professional, this converter is an essential tool for anyone working with binary data.
  </p>
  <div style={
          {marginBottom:"20px"}
        }></div>
          {errorMessage && (
            <Dialog open={Boolean(errorMessage)} onClose={() => setErrorMessage('')}>
              <DialogContent>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {errorMessage}
                </Alert>
              </DialogContent>
            </Dialog>
          )}

          <Box component="form" sx={{ m: 1 }} noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Binary Input (dotted allowed)"
                  value={binaryInput}
                  onChange={(e) => setBinaryInput(e.target.value)}
                  onKeyPress={handleKeyPress} // Trigger conversion on Enter key
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    sx: { color: '#1976d2', '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                  },
                    readOnly: false,
                  }}
                  InputLabelProps={{
                    sx: { color: '#1976d2' },
                  }}
                />
              </Grid>
            </Grid>

            <Button variant="contained" color="primary" onClick={handleConvert} sx={{ marginTop: 2 }}>
              Convert
            </Button>
          </Box>

          {conversionResults && (
            <Box sx={{ marginTop: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Decimal"
                    value={conversionResults.decimal}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Octal"
                    value={conversionResults.octal}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Hexadecimal"
                    value={conversionResults.hexadecimal}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="IPv4 Dotted Decimal"
                    value={conversionResults.ipv4}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="IPv6"
                    value={conversionResults.ipv6}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Subnet Mask (Binary)"
                    value={conversionResults.subnetMask}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Subnet Mask (Dotted Decimal)"
                    value={conversionResults.subnetMaskDottedDecimal}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Container>
      <Utilities />
    </div>
  );
};

export default BinaryConverter;
