import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import './SiteMap.css';
import Settings from './Settings/Setting';

const Sitemap = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Breakpoint for mobile devices

  const siteStructure = [
    { name: 'Home', path: '/' }, //[0]
    {
      name: 'Cisco Courses',
      path: '/ccna/ccna',
      subPages: [
        { name: 'CCNA', path: '/ccna/ccna' },
        { name: 'CCNP', path: '/courses/ccnp' },
        { name: 'CCIE', path: '/courses/ccie' },
      ],
    },//[1]
    { name: 'About', path: '/about' }, //[2]
    { name: 'Contact', path: '/contact' }, // [3]
    {
      name: 'Resources',
      path:'/ccna/calculator/calculators',
      subPages: [
        { name: 'IPv4', path: '/ccna/calculator/ipv4_calculator' },
        { name: 'IPv6', path: '/ccna/calculator/ipv6_calculator' },
        { name: 'Binary', path: '/ccna/calculator/binary_converter' },
        { name: 'Decimal', path: '/ccna/calculator/decimal_converter' },
        { name: 'Octal', path: '/ccna/calculator/octal_converter' },
        { name: 'Hexadecimal', path: '/ccna/calculator/hexadecimal_converter' },
      ],
    }, //4
    {
      name: 'CCNA Commands',
      path: '/ccna/ccna_command',
    }, // 5
    {
      name: 'CCNA 1 Quiz',
      path: '/ccna/ccna1/ccna1_quiz/QuizApp',
    }, // 6
    {
      name: 'CCNA 2 Quiz',
      path: '/ccna/ccna2/ccna2_quiz/QuizApp',
    }, 
    {
      name: 'CCNA 3 Quiz',
      path: '/ccna/ccna3/ccna3_quiz/QuizApp',
    }, 
    {
      name: 'Downloads',
      path: '/downloads',
    }, // 7
  ];

  // Render the page list with recursive nesting for subpages
  const renderPages = (pages) => (
    <ul>
      {pages.map((page, index) => {
        // Skip items without a path or undefined page
        if (!page || !page.path) {
          return null;
        }

        return (
          <li key={index}>
            <Link to={page.path}>
              <Button variant="contained" color="primary" fullWidth >
                {page.name}
              </Button>
            </Link>
            {page.subPages && renderPages(page.subPages)} {/* Recursive call for subPages */}
          </li>
        );
      })}
    </ul>
  );

  return (
    <div className="sitemap-page">
      {/* This is outside the Container, positioned fixed at the top right */}
      <Settings style={{ position: 'fixed', top: '20px', left: '20px', zIndex: 1000 }} />

    <Container maxWidth="lg" className="sitemap">
      <Typography variant="h4" gutterBottom>
        Site Map
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Main Pages</Typography>
          <nav aria-label="Main Pages">
            {renderPages([siteStructure[0], siteStructure[1], siteStructure[2], siteStructure[3]])}
          </nav>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Resources & Tools</Typography>
          <nav aria-label="Resources and Tools">
            {renderPages([siteStructure[4]])}
          </nav>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Additional Features</Typography>
          <nav aria-label="Additional Features">
            {renderPages([siteStructure[5], siteStructure[6], siteStructure[7], siteStructure[8]])} {/* Corrected indices */}
          </nav>
        </Grid>
      </Grid>
      
      {/* Add margin to the Utilities component */}
      <div style={{ marginTop: '40px', marginBottom: '40px' }}></div>

      {/* Optional Section for Mobile Breakpoint */}
      {isMobile && (
        <Grid container justifyContent="center" spacing={1} sx={{ mt: 3 }}>
          <Grid item xs={12}>
            {/* <Link to="/resources/ipv4">
              <Button variant="outlined" fullWidth>
                IPv4 Resources
              </Button>
            </Link> */}
          </Grid>
          <Grid item xs={12}>
            {/* <Link to="/resources/ipv6">
              <Button variant="outlined" fullWidth>
                IPv6 Resources
              </Button>
            </Link> */}
          </Grid>
        </Grid>
      )}
    </Container>
    </div>
  );
};

export default Sitemap;
