// serviceWorkerRegistration.js

// This file is for registering the service worker in a React app
// and ensuring it's properly configured.

// This will handle the registration of the service worker in production builds

export function register() {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  
        navigator.serviceWorker
          .register(swUrl)
          .then((registration) => {
            console.log('Service Worker registered: ', registration);
          })
          .catch((error) => {
            console.error('Error during service worker registration: ', error);
          });
      });
    }
  }
  
 // Unregister the service worker (use this when you want to remove the service worker)
export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister().then((success) => {
          if (success) {
            console.log('Service Worker unregistered successfully');
          } else {
            console.log('Failed to unregister Service Worker');
          }
        });
      })
      .catch((error) => {
        console.error('Error during service worker unregistration: ', error);
      });
  }
}
  