import './StudyConcept.css';
export default function StudyConcept(props){
    return(
      <ul> 
    <li>
      <img src={props.image} alt=''/>

      {/* <img src={props.image} alt= {props.title}/> */}
      <h3>{props.title}</h3>
      <p>{props.description}</p>
    </li>
    </ul>
    );
  }
  