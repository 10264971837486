import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import studyImg from '../../assets/react-core-concepts.png';
import LogoImg from '../../assets/Alpha logo1.png';
import "./Header.css";

const reactDescriptions = ['Fundamental', 'Crucial', 'Core'];

function genRandomInt(max) {
  return Math.floor(Math.random() * (max + 1));
}

export default function Header() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // For xs, sm breakpoint (less than 500px)
  const isLandscape = useMediaQuery('(orientation: landscape)'); // Detect landscape mode

  const ranword = reactDescriptions[genRandomInt(2)];

  return (
    <header>
      {/* Show LogoImg only if screen size is large enough or if it's in landscape mode */}
      {!isSmallScreen || isLandscape ? (
        <img src={LogoImg} alt="Alpha Centauri" />
      ) : null}
      
      <h1>Alpha Centauri Study</h1>
      <p>
        {ranword} Learning Platform Essential for Success in Any Field of Study!
      </p>
    </header>
  );
}
