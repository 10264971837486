// src/components/courses/ccna/Calculator/octal_converter.js 

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Container, Button, Dialog, DialogContent, Alert, AlertTitle, Typography, Grid } from '@mui/material';
import NavigationBar from '../ccna2/NavigationBar'; // Adjust path as needed
import Utilities from '../../../utilities/utilities';
import { Helmet } from 'react-helmet';

// Convert octal to binary
const octalToBinary = (octal) => {
  return parseInt(octal, 8).toString(2);
};

// Convert octal to decimal
const octalToDecimal = (octal) => {
  return parseInt(octal, 8);
};

// Convert octal to hexadecimal
const octalToHexadecimal = (octal) => {
  return parseInt(octal, 8).toString(16).toUpperCase();
};

// Convert octal to IPv4 dotted decimal
const octalToIPv4 = (octal) => {
  const decimal = octalToDecimal(octal);
  const binary = decimal.toString(2).padStart(32, '0');
  const octets = [];
  for (let i = 0; i < 32; i += 8) {
    octets.push(parseInt(binary.slice(i, i + 8), 2));
  }
  return octets.join('.');
};

// Convert octal to IPv6 (assuming the first 128 bits)
const octalToIPv6 = (octal) => {
  const decimal = octalToDecimal(octal);
  const binary = decimal.toString(2).padStart(128, '0');
  const parts = [];
  for (let i = 0; i < 128; i += 16) {
    const part = binary.slice(i, i + 16);
    parts.push(parseInt(part, 2).toString(16));
  }
  return parts.join(':');
};

// Convert octal to dotted decimal subnet mask
const octalToDottedDecimalSubnetMask = (octal) => {
  const decimal = octalToDecimal(octal);
  const binary = decimal.toString(2).padStart(32, '0');
  const octets = [];
  for (let i = 0; i < 32; i += 8) {
    octets.push(parseInt(binary.slice(i, i + 8), 2));
  }
  return octets.join('.');
};

// Validate octal input
const isValidOctal = (input) => {
  return /^[0-7]*$/.test(input);
};

const OctalConverter = () => {
  const [octalInput, setOctalInput] = useState('');
  const [conversionResults, setConversionResults] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleConvert = () => {
    if (isValidOctal(octalInput)) {
      const binary = octalToBinary(octalInput);
      const decimal = octalToDecimal(octalInput);
      const hexadecimal = octalToHexadecimal(octalInput);
      const ipv4 = octalToIPv4(octalInput);
      const ipv6 = octalToIPv6(octalInput);
      const subnetMaskDottedDecimal = octalToDottedDecimalSubnetMask(octalInput);

      setConversionResults({
        binary,
        decimal,
        hexadecimal,
        ipv4,
        ipv6,
        subnetMaskDottedDecimal,
      });
      setErrorMessage('');
    } else {
      setErrorMessage('Octal can only be digits from 0 to 7.');
      setConversionResults(null);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent page reload
      handleConvert();
    }
  };

  <div>

  {/* Page content */}
  <h1>Octal Converter</h1>
  <p>
    Use our Octal Converter to convert octal numbers to decimal, binary, hexadecimal, and other number formats. This tool helps you quickly and easily convert octal numbers to other bases. Whether you're working on programming tasks, studying for exams, or dealing with IT systems, this tool is ideal for anyone needing fast number system conversions.
  </p>

  {/* Add the content for the Octal Converter functionality here */}
  {/* (e.g., octal number input form, conversion options, results display, etc.) */}
</div>

  return (
    <div>
        <Helmet>
    <title>Octal Converter - Convert Octal to Decimal, Binary, Hexadecimal & More | Alpha Centauri Inc.</title>
    <meta
      name="description"
      content="Convert octal numbers to decimal, binary, hexadecimal, and other formats with our Octal Converter tool. Learn how octal to decimal conversion works and get detailed results with examples."
    />
    <meta
      name="keywords"
      content="octal converter, octal to decimal, octal to binary, octal to hexadecimal, number system conversion, octal calculator, decimal calculator, binary calculator, hexadecimal calculator, convert octal"
    />
    <meta
      name="robots"
      content="index, follow"
    />

    {/* Open Graph Meta Tags */}
    <meta property="og:title" content="Octal Converter - Convert Octal to Decimal, Binary, Hexadecimal & More" />
    <meta property="og:description" content="Convert octal numbers to decimal, binary, hexadecimal, and more. Get fast conversions with explanations and examples of how to convert octal to decimal, binary, and other bases." />
    <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
    <meta property="og:url" content="https://alphacentauriinc.com/ccna/calculator/octal_converter" />
    <meta property="og:type" content="website" />

    {/* Twitter Card Meta Tags */}
    <meta name="twitter:title" content="Octal Converter - Convert Octal to Decimal, Binary, Hexadecimal & More" />
    <meta name="twitter:description" content="Convert octal numbers to decimal, binary, hexadecimal, and other formats. Instant conversion with detailed results and examples." />
    <meta name="twitter:image" content="%PUBLIC_URL%/logo192.png" />
    <meta name="twitter:card" content="summary_large_image" />

    {/* Canonical Link */}
    <link rel="canonical" href="/ccna/calculator/octal_converter" />

    {/* Structured Data for Article/Tools */}
    <script type="application/ld+json">
      {`
      {
        "@context": "https://schema.org",
        "@type": "WebApplication",
        "name": "Octal Converter",
        "description": "Convert octal numbers to decimal, binary, hexadecimal, and other formats. Get quick conversions and see examples of octal to decimal, binary, and other bases.",
        "url": "https://alphacentauriinc.com/ccna/calculator/octal_converter",
        "logo": "%PUBLIC_URL%/logo192.png",
        "publisher": {
          "@type": "Organization",
          "name": "Alpha Centauri Inc."
        },
        "mainEntityOfPage": "https://alphacentauriinc.com/ccna/calculator/octal_converter"
      }
        `}
    </script>

  </Helmet>
      <NavigationBar 
        links={['Home','Courses', 'Calculator']} 
        chapters={[]} 
        onSearch={() => {}} 
        searchTerm="" 
        clearSearch={() => {}} 
        hasChapters={false} 
      />   

      <Container 
        sx={{ 
          background: 'rgba(17, 14, 14, 0.6)', 
          padding: 2, 
          borderRadius: 1,
        }}
      >
        <Box sx={{ paddingTop: 4 }}>
          <Typography variant="h4" color="#1976d2" gutterBottom>
            Octal Converter
          </Typography>
          <p>
    Use our Octal Converter to convert octal numbers to decimal, binary, hexadecimal, and other number formats. This tool helps you quickly and easily convert octal numbers to other bases. Whether you're working on programming tasks, studying for exams, or dealing with IT systems, this tool is ideal for anyone needing fast number system conversions.
  </p>
                {/* Add margin to the Utilities component */}
                <div style={{ marginTop: '40px' , marginBottom: '40px'}}></div>
          {errorMessage && (
            <Dialog open={Boolean(errorMessage)} onClose={() => setErrorMessage('')}>
              <DialogContent>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {errorMessage}
                </Alert>
              </DialogContent>
            </Dialog>
          )}

          <Box component="form" sx={{ m: 1 }} noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
              <style>
        {`
            /* For Firefox */
            * {
                scrollbar-width: thin; /* Options: auto, thin */
                scrollbar-color: #1976d2 transparent; /* thumb color and track color */
            }

            /* For WebKit browsers (Chrome, Safari) */
            ::-webkit-scrollbar {
                width: 8px; /* Width of the scrollbar */
            }

            ::-webkit-scrollbar-track {
                background: transparent; /* Background of the scrollbar track */
            }

            ::-webkit-scrollbar-thumb {
                background: #1976d2; /* Color of the scrollbar thumb */
                border-radius: 10px; /* Optional: round the corners of the thumb */
            }

            ::-webkit-scrollbar-thumb:hover {
                background: #155a8a; /* Darker shade on hover */
            }
        `}
    </style>
                <TextField
                  label="Octal Input"
                  value={octalInput}
                  onChange={(e) => setOctalInput(e.target.value)}
                  onKeyPress={handleKeyPress} // Trigger conversion on Enter key
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    sx: { color: '#1976d2', '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                    '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#1976d2' },
                  },
                    readOnly: false,
                  }}
                  InputLabelProps={{
                    sx: { color: '#1976d2' },
                  }}
                />
              </Grid>
            </Grid>

            <Button variant="contained" color="primary" onClick={handleConvert} sx={{ marginTop: 2 }}>
              Convert
            </Button>
          </Box>

          {conversionResults && (
            <Box sx={{ marginTop: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Binary"
                    value={conversionResults.binary}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Decimal"
                    value={conversionResults.decimal}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Hexadecimal"
                    value={conversionResults.hexadecimal}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="IPv4 Dotted Decimal"
                    value={conversionResults.ipv4}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="IPv6"
                    value={conversionResults.ipv6}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Subnet Mask (Dotted Decimal)"
                    value={conversionResults.subnetMaskDottedDecimal}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&:hover fieldset': {
                          borderColor: '#1976d2',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#1976d2',
                      },
                      '& .MuiInputBase-input': {
                        color: '#1976d2',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Container>
      <Utilities />
    </div>
  );
};

export default OctalConverter;